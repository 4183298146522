/* eslint-disable */
import { getMaskedCardInformationFromToken } from '../components/NewOffer/NewOfferCyberSource/paymentUtil';
import { States_US } from './States';
import { deviceInfoPayload } from './deviceInformation';
export const pinFlowPaymentPayload = (
    offerdata: any,
    billingInfo: any,
    contactInfo: any,
    token: any,
    refId?: any,
    trId?: any,
    ipAddress?: any,
    pinLeadDetails?: any,
    isCallMe?: boolean,
    pinDetails?: any,
    mailingData?: any
) => {
    const pinData = pinDetails?.pin || ''
    const stateCode = (state: any) => {
        const stateObj = States_US?.filter((item: any) => item?.name === state);
        return stateObj && stateObj[0]?.code;
    };
    const email = contactInfo?.email || pinLeadDetails?.email
    const phNo = contactInfo?.phone || pinLeadDetails?.phone

    const cardObj = getMaskedCardInformationFromToken(token);
    const roomTypes = offerdata?.roomTypes && offerdata?.roomTypes[0];
    const currentDate = new Date();
    const formattedDate = currentDate?.toISOString() || '';
    const paymentId = `${contactInfo?.lastName}-${formattedDate}-${roomTypes?.roomTypeDescription}`;
    const depositAmt = (roomTypes?.depositAmount?.includes('$') && roomTypes?.depositAmount.split('$')) || '';
    const totalAmt = depositAmt && depositAmt[1];

    const deviceInfo = deviceInfoPayload();
    deviceInfo.ipAddress = ipAddress;

    const firstTwoChars = offerdata?.loc?.slice(0, 2) || '';
    const chargeSiteEnvVal = process.env.REACT_APP_CLIENT_OPB_CHARGE_SITE_ID_KEY || 'WCC';
    const chargeSite = `${firstTwoChars}*${chargeSiteEnvVal}`;
    const payloadRequest: any = {
        payment: {
            tokenInformation: {
                transientTokenJwt: token,
                cardinalJwt: '',
            },
            clientReferenceInformation: {
                code: 'WEB_PBT',
                associate: false,
                customerId: contactInfo?.lastName,
                merchantGroupId: process.env.REACT_APP_MERCHANT_GROUPID_3DS,
                paymentId: paymentId, //currentTimestamp +roomTypes?.roomTypeDescription
                referenceId: refId,
                srcSystemName: 'WEB_PBT',
                transactionId: trId,
                storeCard: false,
            },
            orderInformation: {
                amountDetails: {
                    totalAmount: totalAmt,
                    currency: 'USD',
                },
                billTo: {
                    address1: billingInfo?.address,
                    address2: '',
                    administrativeArea: billingInfo?.state,
                    country: 'US',
                    email: email || '',
                    firstName: contactInfo?.firstName,
                    lastName: contactInfo?.lastName,
                    locality: billingInfo?.city,
                    postalCode: billingInfo?.zipCode,
                    phoneNumber: phNo || '',
                },
            },
            deviceInformation: deviceInfo,
            merchantDefinedInformation: [
                { key: '1', value: process.env.REACT_APP_CLIENT_OPB_CYBERSOURCE_OTM_PROSPECT_ID_KEY || 'PBTC' },
                { key: '2', value: offerdata?.loc },
                { key: '3', value: chargeSite },
                { key: '4', value: pinData },
            ],
        },
        confirm: {
            sourceName: process.env.REACT_APP_CLIENT_OPB_CYBERSOURCE_OTM_PROSPECT_ID_KEY || 'PBTC',
            holdSessionId: '',
            pin: pinData,
            loc: offerdata?.loc,
            startDate: '',
            endDate: '',
            holidaySurcharge: '',
            holidayName: '',
            additionalNightCharge: '',
            totalCost: '',
            basePrice: '',
            retailPrice: '',
            additionalNights: 0,
            tour: {
                offerId: offerdata?.offerCode,
                tourType: '',
                tourSiteCode: '',
                salesCenterCode: '',
                appointmentDate: '',
            },
            roomInformation: {
                accomodationCode: roomTypes?.accomodationCode,
                facilityPropertyID: '',
                miniHotelCode: '',
                propertyCode: '',
                rateCode: '',
                roomPoolCode: '',
                roomTypeCode: '',
                bookingRoomType: '',
                numberOfDays: roomTypes?.numberOfDays,
                numberOfNights: roomTypes?.numberOfNights,
                numberOfGuests: roomTypes?.sleepCapacity,
                numberOfAdults: '1',
                numberOfChildren: '0',
            },
            prospectInformation: {
                firstName: contactInfo?.firstName || '',
                lastName: contactInfo?.lastName || '',
                firstName2: '',
                lastName2: '',
                line1: mailingData?.address || '',
                city: mailingData?.city || '',
                stateProvince: stateCode(mailingData?.state) || '',
                postalCode: mailingData?.zipCode || '',
                telephoneNumber: phNo || '',
                emailAddress: email || '',
                doNotCall: isCallMe ? 'Y' : 'N',
                doNotMail: isCallMe ? 'Y' : 'N',
            },
            charge: {
                billingAddressLine1: billingInfo?.address,
                billingEmailAddress: email || '',
                billingFirstName: contactInfo?.firstName,
                billingLastName: contactInfo?.lastName,
                billingPhoneNumber: phNo || '',
                billingCity: billingInfo?.city,
                billingCountryCode: 'US',
                billingStateProvinceCode: stateCode(billingInfo?.state),
                billingZip: billingInfo?.zipCode || '',
                cardCheck: cardObj?.maskedCardNumber,
                currencyCode: 'USD',
                expirationDate: cardObj?.expiration,
                merchantID: process.env.REACT_APP_MERCHANTID_3DS,
                paymentMethodCode: cardObj?.cardType,
                preferredInvitationNumber: pinData,
                totalAmount: totalAmt,
                merchantGroupId: process.env.REACT_APP_MERCHANT_GROUPID_3DS,
            },
        },

    };
    return payloadRequest;
};
