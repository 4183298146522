/* eslint-disable */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '../../redux/store';
import { reservationContactInfoType, newOfferDetailsType, urlDataInfoType, errorType } from './types';
import { getOfferDetailsDataByLOC, createNewOfferLead, updateNewOfferLead } from './NewOfferDetailsService';
import staticOfferData from '../siteContent/newOfferData.json';
const getOfferType: any = sessionStorage.getItem('offer-type');
const isPaid = getOfferType === 'paid' ? true : false;

const isStateErr = isPaid ? false : true;
const initialState: newOfferDetailsType = {
  reservationContactInfo: { firstName: '', lastName: '', phoneNumber: '', emailAddress: '', isOpt: false },
  loading: false,
  hasError: false,
  newOfferLeadDetail: '',
  urlData: '',
  hasLeadError: false,
  isPaynowDisabled: true,
  newOfferData: undefined,
  resContactErr: { firstError: true, lastError: true, phoneError: true, emailError: true, stateError: isStateErr },
  isPinOptFlow:false
};
const newOfferSlice = createSlice({
  name: 'contactInfoDetail',
  initialState,
  reducers: {
    setContactInformationDetails(state: newOfferDetailsType, action: PayloadAction<reservationContactInfoType>) {
      state.reservationContactInfo = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        emailAddress: action.payload.emailAddress,
        phoneNumber: action.payload.phoneNumber,
        state: isPaid ? '' : action.payload.state,
        isOpt: action.payload.isOpt,
      };
    },
    setResContactErrInfo(state, action: PayloadAction<any>) {
      const isState = isPaid ? false : action.payload?.stateError;
      state.resContactErr = {
        firstError: action.payload.firstError,
        lastError: action.payload.lastError,
        phoneError: action.payload.phoneError,
        emailError: action.payload.emailError,
        stateError: isState,
      };
    },
    setPayNowValid(state, action: PayloadAction<boolean>) {
      state.isPaynowDisabled = action.payload;
    },
    setUrlDetails(state: newOfferDetailsType, action: PayloadAction<any>) {
      const flow = action.payload.flow || '';
      const destination = action.payload.destination || '';
      const department = action.payload.department || '';
      const resort = action.payload.resort || '';
      const brand = action.payload.brand || '';

      state.urlData = {
        url: action.payload.url,
        loc: action.payload.loc,
        isPaid: action.payload.isPaid,
        flow: flow,
        destination: destination,
        department: department,
        resort: resort,
        brand: brand,
      };
    },

    receiveNewOffer(state, action: PayloadAction<any>) {
      if (action.payload) {
        const {
          payload: { hasError, newOfferData },
        } = action;
        state.newOfferData = action.payload.newOfferData;
        state.hasError = action.payload.hasError;
        state.loading = false;
      }
    },
    setNewOfferLead(state, action: PayloadAction<any>) {
      if (action.payload) {
        const leadInfoAction: any = action.payload.leadDetail || '';
        const leadDetails = (leadInfoAction?.items && leadInfoAction?.items[0]?.id) || '';
        state.newOfferLeadDetail = leadDetails;
      }
    },
    reSetNewOfferLead(state) {
      state.newOfferLeadDetail = '';
    },
    setNewOfferUpdateLead(state, action: PayloadAction<any>) {
      if (action.payload) {
        state.newOfferUpdateLeadDetail = action.payload.updateLeadDetail;
        // const leadInfoAction: any = action.payload.leadDetai || '';
        // const leadDetails = (leadInfoAction?.items && leadInfoAction?.items[0]?.id) || '';
        //state.newOfferLeadDetail = leadDetails;
        //state.hasLeadError = action.payload.hasError;
      }
    },
    setNewOfferLoader(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    reSetContactInfo(state) {
      state.reservationContactInfo = { firstName: '', lastName: '', phoneNumber: '', emailAddress: '', isOpt: false };
      state.hasError = false;
      state.newOfferLeadDetail = '';
      state.hasLeadError = false;
      state.isPaynowDisabled = true;
      state.resContactErr = {
        firstError: true,
        lastError: true,
        phoneError: true,
        emailError: true,
        stateError: isStateErr,
      };
    },
    clearContactInfo(state) {
      state.reservationContactInfo = { firstName: '', lastName: '', phoneNumber: '', emailAddress: '', isOpt: false };
      state.hasError = false;
      state.newOfferLeadDetail = '';
      state.hasLeadError = false;
      state.isPaynowDisabled = true;
      state.resContactErr = {
        firstError: true,
        lastError: true,
        phoneError: true,
        emailError: true,
        stateError: isStateErr,
      };
      state.newOfferData = undefined;
      state.urlData = '';
    },
    setOfferFlow(state){
      state.isPinOptFlow = true;
    }
  },
});
export const {
  setContactInformationDetails,
  setNewOfferLead,
  setUrlDetails,
  setPayNowValid,
  setResContactErrInfo,
  setNewOfferUpdateLead,
  reSetNewOfferLead,
  reSetContactInfo,
  clearContactInfo,
  setOfferFlow,
} = newOfferSlice.actions;
export const fetchOfferDetails = (loc: string, defaultLoc: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(newOfferSlice.actions.setNewOfferLoader(true));
  const offerDetails: any = await getOfferDetailsDataByLOC(loc, defaultLoc);
  const isErr: any = new URLSearchParams(window?.location?.search)?.get('apifail') || false;

  const tmpObj = { newOfferData: staticOfferData, hasError: false, loading: false };
  const finalData: any = isErr ? tmpObj : offerDetails;
  dispatch(newOfferSlice.actions.receiveNewOffer(finalData));
  dispatch(newOfferSlice.actions.setNewOfferLoader(false));
};
export const createNewOfferLeadRequest = (param: any): AppThunk => async (dispatch: AppDispatch) => {
  const leadDetail: any = await createNewOfferLead(param);
  dispatch(newOfferSlice.actions.setNewOfferLead(leadDetail));
};
export const updateNewOfferLeadRequest = (param: any): AppThunk => async (dispatch: AppDispatch) => {
  const leadDetail: any = await updateNewOfferLead(param);
  dispatch(newOfferSlice.actions.setNewOfferUpdateLead(leadDetail));
};

export default newOfferSlice.reducer;
