/* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ErrorCustom from './components/common/ErrorBoundary/ErrorCustom';
import Footer from './components/common/Footer/Footer';
import Header from './components/common/Header/Header';
import CustomSpinner from './components/common/Spinner';
import SiteContentProvider from './components/hoc/SiteContentProvider';
import OfferNavigation, { NavigationSteps } from './components/OfferDetails/OfferNavigation';
import ConuntdownTimer from './components/OfferEligibility/CountdownTimer';
import OfferStateNavigation from './components/OfferStateSearch/OfferStateNavigation';
import OfferAvailability from './features/offerAvailability/OfferAvailabilityContainer';
import OfferConfirmationContainer from './features/offerConfirmation/OfferConfirmationContainer';
import OfferContactInformationContainer from './features/offerContactInfo/OfferContactInformationContainer';
import OfferDestination from './features/offerDestination/OfferDestinationContainer';
import OfferDetails from './features/offerDetails/OfferDetailsContainer';
import OfferEligibility from './features/offerEligibility/OfferEligibilityContainer';
import OfferSearch from './features/offerSearch/OfferSearchContainer';
import OfferStateSelect from './features/OfferStateSelect/OfferStateSelect';
import OfferSummaryContainer from './features/offerSummary/OfferSummaryContainer';
// new Offer component
import NewOffer from './features/newOffer/NewOfferContainer';
import NewOfferPaymentDetails from './features/newOfferPayment/NewOfferPaymentDetailsContainer';
import NewOfferThankYou from './features/newOfferThankYou/NewOfferThankYou';
import TestDetails from './features/TestDetails/TestDetails';
import DefaultContainer from './features/newOffer/DefaultContainer';
import NewOfferError from './components/NewOffer/NewOfferError/NewOfferError';
import OfferFooter from './components/common/CommonOfferComponent/OfferFooter/OfferFooter';
//import PinFlowSearchContainer from './features/PinOptimizedSearchOffer/PinFlowSearchContainer';
import DefaultPinOptFlowContainer from './features/DefaultPinOptFlow/DefaultPinOptFlowContainer';
import PinOptLeadInfoContainer from './features/PinOptLeadInfoContainer/PinOptLeadInfoContainer';
import PinOptOfferDetailsContainer from './features/PinOptOfferDetails/PinOptOfferDetailsContainer';
import PinOptFlowError from './features/PinOptFlowError/PinOptFlowError';

import Metadata from './features/siteTheme/Metadata';
import { RootState } from './redux/rootReducer';
import { getCurrentBrand } from './util';
import {
  OFFER_CONFIRMATION_PATH,
  OFFER_CONTACT_INFO_PATH,
  OFFER_DATES_PATH,
  OFFER_DESTINATION_PATH,
  OFFER_ELIGIBILITY_PATH,
  OFFER_LOC_CONFIRMATION_PATH,
  OFFER_LOC_CONTACT_INFO_PATH,
  OFFER_LOC_DATES_PATH,
  OFFER_LOC_ELIGIBILITY_PATH,
  OFFER_LOC_REVIEW_PATH,
  OFFER_REVIEW_PATH,
  OFFER_STATE_DESTINATION_PATH,
  VERIFY,
  VIEW_LOC_OFFERS_PATH,
  // eslint-disable-next-line prettier/prettier
  VIEW_OFFERS_PATH,
  NEWOFFER_CONTACT_INFORMATION_PATH,
  NEWOFFER_PAYMENT_PATH,
  NEWOFFER_THANKYOU_PATH,
  TEST_DATA_DETAILS_PATH,
  NEWOFFER_ERROR_PATH,
  TMVC_URL,
  PIN_OPT_VALIDATE_PATH,
  PIN_OPT_OFFER_DETAILS_PATH,
  PIN_OPT_ERROR,
} from './util/strings';
interface IProps {
  lang: string;
}
const TIMER_DURATION_IN_SECONDS = 900;
const Main = (props: IProps): JSX.Element => {
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const timerDuration = process.env.REACT_APP_TIMER_DURATION_IN_SECONDS
    ? parseInt(process.env.REACT_APP_TIMER_DURATION_IN_SECONDS, 10)
    : TIMER_DURATION_IN_SECONDS;
  const openDateSelector: boolean | undefined = useSelector(
    (state: RootState) => state.availabilityDates.openDateSelector,
  );

  const currentNavigationStep: NavigationSteps = useSelector(
    (state: RootState) => state.offerDetails.currentNavigationStep,
  );

  const offers = useSelector((state: RootState) => state.offerDetails);
  const isDatedOffer: boolean = offers && offers.isDatedOffer && offers.isDatedOffer === true ? true : false;
  const timerDisplayPaths = [
    NavigationSteps.OFFER_ELIGIBILITY,
    NavigationSteps.OFFER_CONTACT_INFO,
    NavigationSteps.OFFER_REVIEW,
  ];
  const isTmvc = window.location?.origin.includes(TMVC_URL);
  const currentDate = new Date();
  let holdStartTimeFromStore = useSelector((state: RootState) => state.availabilityDates.holdStartTime);
  holdStartTimeFromStore = holdStartTimeFromStore ? holdStartTimeFromStore : new Date();
  const holdStartTime: Date =
    typeof holdStartTimeFromStore === 'string' ? new Date(holdStartTimeFromStore) : holdStartTimeFromStore;
  const timeElapsed: number = Math.abs(Math.floor((currentDate.getTime() - holdStartTime.getTime()) / 1000));
  let timerTimeReamaining = timerDuration - timeElapsed;
  timerTimeReamaining = timerTimeReamaining > 0 ? timerTimeReamaining : 1;
  const renderTimer =
    isDatedOffer && !openDateSelector && timerDisplayPaths.indexOf(currentNavigationStep) >= 0 ? (
      <ConuntdownTimer countdownDuration={timerTimeReamaining} isMobile={isMobile}></ConuntdownTimer>
    ) : (
      <></>
    );
  return (
    <SiteContentProvider>
      <Metadata currentBrand={getCurrentBrand()}></Metadata>
      <CustomSpinner></CustomSpinner>
      <BrowserRouter>
        <Switch>
          {/* PIN path */}
          <Route
            path={[
              OFFER_DESTINATION_PATH,
              VIEW_OFFERS_PATH,
              OFFER_DATES_PATH,
              OFFER_ELIGIBILITY_PATH,
              OFFER_CONTACT_INFO_PATH,
              OFFER_REVIEW_PATH,
              OFFER_CONFIRMATION_PATH,
            ]}
          >
            {isMobile ? renderTimer : <></>}
            <Header></Header>
            <OfferNavigation currentStep={currentNavigationStep} isDatedOffer={isDatedOffer}></OfferNavigation>

            <Route
              exact
              path={OFFER_DESTINATION_PATH}
              render={(props) => <OfferDestination {...props} stepIndex={1} />}
            />
            <Route exact path={VIEW_OFFERS_PATH} render={(props) => <OfferDetails {...props} stepIndex={1} />} />
            <Route exact path={OFFER_DATES_PATH} render={(props) => <OfferAvailability {...props} stepIndex={2} />} />
            <Route exact path={OFFER_CONFIRMATION_PATH} render={(props) => <OfferConfirmationContainer />} />
            <Route path={[OFFER_ELIGIBILITY_PATH, OFFER_CONTACT_INFO_PATH, OFFER_REVIEW_PATH, OFFER_CONFIRMATION_PATH]}>
              {!isMobile && renderTimer}
              <Route
                exact
                path={OFFER_ELIGIBILITY_PATH}
                render={(props) => <OfferEligibility {...props} stepIndex={3} />}
              />
              <Route exact path={OFFER_CONTACT_INFO_PATH} render={(props) => <OfferContactInformationContainer />} />
              <Route exact path={OFFER_REVIEW_PATH} render={(props) => <OfferSummaryContainer />} />
            </Route>
          </Route>
          {/* eslint-disable */}
          <Route path={[VERIFY]}>
            <Route
              exact
              path={VERIFY}
              render={() => (
                <>
                  <Header></Header>
                  <OfferStateSelect />
                </>
              )}
            />
          </Route>
          <Route exact path={TEST_DATA_DETAILS_PATH} render={() => <TestDetails />} />
          <Route
            exact
            path={NEWOFFER_CONTACT_INFORMATION_PATH}
            render={() => (
              <>
                <NewOffer />
                <OfferFooter />
              </>
            )}
          />
          <Route
            exact
            path={NEWOFFER_PAYMENT_PATH}
            render={() => (
              <>
                <NewOfferPaymentDetails />
                <OfferFooter />
              </>
            )}
          />
          <Route
            exact
            path={NEWOFFER_THANKYOU_PATH}
            render={() => (
              <>
                <NewOfferThankYou />
                <OfferFooter />
              </>
            )}
          />
          <Route
            exact
            path={PIN_OPT_VALIDATE_PATH}
            render={() => (
              <>
                <PinOptLeadInfoContainer />
                <OfferFooter />
              </>
            )}
          />
          <Route
            exact
            path={PIN_OPT_OFFER_DETAILS_PATH}
            render={() => (
              <>
                <PinOptOfferDetailsContainer />
                <OfferFooter />
              </>
            )}
          />
          <Route
            exact
            path={NEWOFFER_ERROR_PATH}
            render={() => (
              <>
                <NewOfferError />
                <OfferFooter />
              </>
            )}
          />
          <Route
            exact
            path={PIN_OPT_ERROR}
            render={() => (
              <>
                <PinOptFlowError />
                <OfferFooter />
              </>
            )}
          />

          {isTmvc && (
            <Route
              exact
              path="/:pinid([aA-zZ\d]{5,20})?"
              render={() => (
                <>
                  <DefaultPinOptFlowContainer />
                  <OfferFooter />
                </>
              )}
            />
          )}
          {/* eslint-enable */}
          {/* eslint-disable-next-line no-useless-escape */}
          <Route
            exact
            path="/:pinid([aA-zZ\d]{5,20})?"
            render={() => (
              <>
                <Header></Header>
                <OfferSearch />
              </>
            )}
          />
          {/* <Route
            exact
            path="/:pinid([aA-zZ\d]{5,20})?"
            render={() => (
              <>
                <PinOptimizedSearch />
                <OfferFooter />
              </>
            )}
          />*/}
          <Route path="/*" render={() => <ErrorCustom />} />
        </Switch>
      </BrowserRouter>
      {!isTmvc && (
        <div className="container">
          <Footer></Footer>
        </div>
      )}
    </SiteContentProvider>
  );
};

export default Main;
