/* eslint-disable */
import React, { FC, useState } from 'react';
import { withRouter } from 'react-router';
import withLocalization from '../hoc/withLocalization';
import ParseHtml from '../common/ParseHtml';
import LeadSubmitButton from './LeadSubmitButton';
import ConsentAgreementModal from '../MarketingConsentAgreement/ConsentAgreementModal';
import { maskName, maskEmail, maskPhoneNumber } from './LeadInfoUtils';
import { capitalizeFirstLetter } from '../../util/CommonLogic';
import './LeadInfoDetails.scss';

interface SearchPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
  leadDetails?: any;
}
const EditLeadInfoDetails: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { localize, leadDetails } = props;
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [phoneErr, setPhoneErr] = useState('');
  const [isValidForm, setIsValidForm] = useState(false);
  const [isCheckedContactMe, setIsCheckedContactMe] = useState(false);
  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const capFirstName = capitalizeFirstLetter(leadDetails?.firstName);
  const capLastName = capitalizeFirstLetter(leadDetails?.lastName);
  const fullName = `${capFirstName} ${capLastName}`;

  const checkEmpty = (val: any) => {
    const isEmpty = val?.trim() === '' ? true : false;
    return isEmpty;
  };

  const isValidEmail = (val: any) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !emailPattern.test(val);
  };
  const isInvalidPhone = (val: any) => {
    const phoneValidator: RegExp = new RegExp(/^[1-9][0-9]{9}$/);
    return !phoneValidator.test(val);
  };

  const chkValidMail = (val) => {
    if (checkEmpty(val)) {
      return `preview-sales-engine.static-content.copy.contact-information-page.email-error#required`;
    } else if (isValidEmail(val)) {
      return `preview-sales-engine.static-content.copy.contact-information-page.email-error#invalid`;
    } else {
      return '';
    }
  };
  const chkValidPhone = (val) => {
    if (checkEmpty(val)) {
      return `preview-sales-engine.static-content.copy.contact-information-page.phone-error#required`;
    } else if (isInvalidPhone(val)) {
      return `preview-sales-engine.static-content.copy.contact-information-page.phone-error#invalid`;
    } else {
      return '';
    }
  };
  const clickMarketingConsent = () => {
    setConsentModalOpen(true);
  };

  const handleEditMailChange = (evt) => {
    const emailVal = evt.target.value;
    setEmail(emailVal);
    const mailErrMsg = chkValidMail(emailVal);
    setEmailErr(mailErrMsg);
    if (mailErrMsg) {
      setIsValidForm(false);
    } else {
      if (!phoneErr && (phone || leadDetails?.phone)) {
        setIsValidForm(true);
      } else {
        setIsValidForm(false);
      }
    }
  };
  const handleEditPhoneChange = (evt) => {
    const phoneVal = evt.target.value;
    setPhone(phoneVal);
    const phoneErrMsg = chkValidPhone(phoneVal);
    setPhoneErr(phoneErrMsg);
    if (phoneErrMsg) {
      setIsValidForm(false);
    } else {
      if (!emailErr && (email || leadDetails?.email)) {
        setIsValidForm(true);
      } else {
        setIsValidForm(false);
      }
    }
  };
  const handleCheckboxClick = () => {
    setIsCheckedContactMe(!isCheckedContactMe);
  };
  const closeModal = () => {
    setConsentModalOpen(false);
  };

  return (
    <>
      {consentModalOpen && <ConsentAgreementModal closeModal={closeModal} />}
      <div className="leadInfoHeadContainer">
        <span className="leadInfo-head-wrapper">
          {localize('preview-sales-engine.static-content.copy.pbeo.verify-page-header')}
        </span>

        <span className="leadInfo-head-desc">
          {localize('preview-sales-engine.static-content.copy.pbeo.verify-page-description')}
        </span>
      </div>
      <div className="leadInfo-form">
        <div className="leadInfo-RowContainer">
          <span className="leadInfo-form-lbl">
            {localize('preview-sales-engine.static-content.copy.associate-search-page.name-label')}
          </span>
          <span className="leadInfo-form-data">{maskName(fullName)}</span>
        </div>
        <div className="leadInfo-RowContainer">
          <span className="leadInfo-form-lbl">
            {localize('preview-sales-engine.static-content.copy.associate-search-page.email-label')}
          </span>
          {leadDetails?.email ? (
            <span className="leadInfo-form-data">{maskEmail(leadDetails?.email)}</span>
          ) : (
            <input
              type="email"
              value={email}
              id="editEmail"
              onChange={handleEditMailChange}
              className="leadInfo-input-edit"
              maxLength={35}
              autoComplete={'email'}
            />
          )}
          {emailErr && !leadDetails?.email && <span className="res-inline-err">{localize(emailErr)}</span>}
        </div>
        <div className="leadInfo-RowContainer">
          <span className="leadInfo-form-lbl">
            {localize('preview-sales-engine.static-content.copy.associate-search-page.phone-label')}
          </span>
          {leadDetails?.phone ? (
            <span className="leadInfo-form-data">{maskPhoneNumber(leadDetails?.phone)}</span>
          ) : (
            <input
              type="tel"
              value={phone}
              id="editPhone"
              onChange={handleEditPhoneChange}
              className="leadInfo-input-edit"
              maxLength={10}
              autoComplete={'tel-national'}
            />
          )}
          {phoneErr && !leadDetails?.phone && <span className="res-inline-err">{localize(phoneErr)}</span>}
        </div>

        <div className="leadInfo-consent-container">
          <input
            type="checkbox"
            // checked={isPaymentCheck}
            id="checkBox-consent"
            onChange={handleCheckboxClick}
            name="checkbox"
            className="leadInfo-consent-CheckBox"
            aria-label="consent-checkBox"
            onClick={handleCheckboxClick}
          />
          <div className="leadInfo-consent-Desc-container">
            <span className="leadInfo-consent-Desc">
              <ParseHtml
                htmlInput={localize('preview-sales-engine.static-content.copy.pbeo.mktg-consent-modal-description')}
              ></ParseHtml>
            </span>
            {/*<button className="marketing-consent-btn" onClick={() => clickMarketingConsent()}>
              {localize('preview-sales-engine.static-content.copy.pbeo.consent-agreement-modal-link')}
            </button>*/}
          </div>
        </div>
        <LeadSubmitButton
          isValidForm={isValidForm}
          isEdit={true}
          email={email}
          phone={phone}
          ischecked={isCheckedContactMe}
        />
      </div>
    </>
  );
};
export default withRouter(withLocalization(EditLeadInfoDetails));
