/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';
import { replacePlaceholder } from '../../util/CommonLogic';
import './BookingTravelModal.scss';
import galleryModalCloseLogo from '../../features/siteTheme/Brands/tmvcs/logo/galleryModalCloseLogo.svg';
import ParseHtml from '../common/ParseHtml';

import phoneLogo from '../../features/siteTheme/Brands/tmvcs/logo/phone.svg';
interface consentProps {
  localize?: any;
  image?: string;
  closeModal: () => void;
}
const BookingTravelModal: FC<any> = (props: consentProps): ReactElement => {
  const { localize, closeModal } = props;
  const bookingTrDetails = localize('preview-sales-engine.static-content.copy.pbeo.booking-travel-dates-description');
  const phoneIcon = `  <img src="${phoneLogo}" alt="Call Now" class="book-phone-icon" />`;
  const updateBookDetails = replacePlaceholder(bookingTrDetails, '{fa-phone}', phoneIcon);

  return (
    <div className="book-modal-overlay-part">
      <div className="book-modal-part">
        <div className="book-modal-head-container">
          <div className="book-modal-text-head">
            {localize('preview-sales-engine.static-content.copy.pbeo.booking-travel-dates-header')}
          </div>
          <div className="book-modal-part-close">
            <input
              className="modal-close-btn"
              src={galleryModalCloseLogo}
              type="image"
              onClick={closeModal}
              aria-label="close"
            />
          </div>
        </div>
        <div className="book-modal-content-part">{<ParseHtml htmlInput={updateBookDetails}></ParseHtml>}</div>
      </div>
    </div>
  );
};
export default React.memo(withLocalization(withBrands(BookingTravelModal)));
