/* eslint-disable */

import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import availabilityDates from '../features/offerAvailability/offerAvailabilitySlice';
import confirmation from '../features/offerConfirmation/offerConfirmationSlice';
import offerDetails from '../features/offerSearch/offerSearchSlice';
import siteContent from '../features/siteContent/siteContentSlice';
import newOfferDetails from '../features/newOffer/NewOfferSlice';
import newOfferDettails from '../features/newOffer/NewOfferSlice'
import newOfferPaymentDetails from '../features/newOfferPayment/NewOfferPaymentSlice';
import pinOptFlowDetails from '../features/PinOptLeadInfoContainer/PinOptFlowOfferSlice'

const offerDetailPersistConfig = {
  key: 'offerDetails',
  storage: storage,
  blacklist: [
    'hasError',
    'showProspect',
    'currentNavigationStep',
    'loading',
    'searchMode',
    'error',
    'showCTA',
    'isPackageSold',
  ],
};
const availabilityDatesPersistConfig = {
  key: 'availabilityDates',
  storage: storage,
  blacklist: [
    'hasNoAvailability',
    'hasError',
    'selectedDate',
    'consecutiveSelectedDates',
    'dates',
    'isLoading',
    'dateMonthRegistry',
    'previousCalendarPosition',
    'currentCalendarPosition',
    'isHoldFailed',
    'extraNights',
  ],
};
const confirmationPersistConfig = {
  key: 'confirmation',
  storage: storage,
  blacklist: [
    'loading',

    'error',
    'hasError',
    'paymentContextId',
    'flexFormSubmitHandler',
    'transientTokenJwt',
    'paymentFormValid',
    'paymentCheckBox',
    'hasPaymentContextError',
    'sourceId',
  ],
};
const newOfferDetailPersistConfig = {
  key: 'newOfferDetails',
  storage: storage,
  blacklist: ['currentNavigationStep', 'searchMode', 'error', 'showCTA', 'isPackageSold'],
};
const newOfferPaymentPersistConfig = {
  key: 'offerDetails',
  storage: storage,
  blacklist: ['billingDetailInfo', 'BillingDetailErr', 'pageLoadTime', 'isPaymentCheckBox', 'isFLexFormValid'],
};
const pinFlowOptDetailPersistConfig = {
  key: 'pinOptFlowDetails',
  storage: storage,
  blacklist: ['error']
}
const offerDetailsReducer = persistReducer(offerDetailPersistConfig, offerDetails);
const availabilityDatesReducer = persistReducer(availabilityDatesPersistConfig, availabilityDates);
const confirmationReducer = persistReducer(confirmationPersistConfig, confirmation);
const newOfferDetailsReducer = persistReducer(newOfferDetailPersistConfig, newOfferDetails);
const newOfferPaymentReducer = persistReducer(newOfferPaymentPersistConfig, newOfferPaymentDetails);
const pinOptFlowDetailsReducer = persistReducer(pinFlowOptDetailPersistConfig, pinOptFlowDetails)
const rootReducer = combineReducers({
  siteContent,
  offerDetails: offerDetailsReducer,
  availabilityDates: availabilityDatesReducer,
  confirmation: confirmationReducer,
  newOfferDetails: newOfferDetailsReducer,
  newOfferPaymentDetails: newOfferPaymentReducer,
  pinOptFlowDetails: pinOptFlowDetailsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
