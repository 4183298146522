/* eslint-disable */
import { CURRENCY_CODE } from './strings';
import { deviceInfoPayload } from './deviceInformation';
export const pinFlowAuthPayload = (offerdata: any, billingInfo: any, contactInfo: any, token: any, ipAddr?: any, pinLeadDetails?: any) => {
    const roomTypes = offerdata?.roomTypes && offerdata?.roomTypes[0];
    const currentDate = new Date();
    const formattedDate = currentDate?.toISOString() || '';
    const paymentId = `${contactInfo?.lastName}-${formattedDate}-${roomTypes?.roomTypeDescription}`;
    const depositAmt = (roomTypes?.depositAmount?.includes('$') && roomTypes?.depositAmount.split('$')) || '';
    const totalAmt = depositAmt && depositAmt[1];
    const email = contactInfo?.email || pinLeadDetails?.email
    const deviceInfo = deviceInfoPayload();
    deviceInfo.ipAddress = ipAddr;
    const payloadRequest: any = {
        tokenInformation: {
            jti: '',
            transientTokenJwt: token,
        },
        clientReferenceInformation: {
            code: 'WEB_PBT',
            merchantGroupId: process.env.REACT_APP_MERCHANT_GROUPID_3DS,
            paymentId: paymentId,
            referenceId: '',
        },
        orderInformation: {
            amountDetails: {
                totalAmount: totalAmt,
                currency: CURRENCY_CODE,
            },
            billTo: {
                address1: billingInfo?.address,
                address2: '',
                administrativeArea: billingInfo?.state,
                country: 'US',
                email: email || '',
                firstName: contactInfo?.firstName,
                lastName: contactInfo?.lastName,
                locality: billingInfo?.city,
                postalCode: billingInfo?.zipCode,
            },
        },
        deviceInformation: deviceInfo,
        merchantInformation: {
            merchantIdentifier: process.env.REACT_APP_MERCHANTID_3DS,
            currencyCode: CURRENCY_CODE,
        },
    };
    return payloadRequest;
};
