/* eslint-disable */
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import withLocalization from '../../components/hoc/withLocalization';
import OfferHeader from '../../components/common/CommonOfferComponent/OfferHeader/OfferHeader';
import ParseHtml from '../../components/common/ParseHtml';
import { resetPinOptFlowDetails } from '../PinOptLeadInfoContainer/PinOptFlowOfferSlice';
//import { useMediaQuery } from 'react-responsive';
import { resetProspectDetails, resetOfferSearch, resetShowCTA } from '../offerSearch/offerSearchSlice';
import phoneLogo from '../../features/siteTheme/Brands/tmvcs/logo/phone.svg';
import { OFFER_EXPIRED } from '../../util';
import './PinOptFlowError.scss';

const PinOptFlowError: FC<any> = (props: any): ReactElement => {
  const { localize, localizeImages } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  //const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const stateData: any = location?.state || {};
  const stateNo = stateData?.errPhNo || '';
  const errorCode = stateData?.errorCode || '';
  const isPackageSold = stateData?.isPkgSold;
  const isOfferExpired = errorCode?.toLocaleUpperCase() === OFFER_EXPIRED;
  const { path } = localizeImages('preview-sales-engine.static-content.images.not-my-info-hero', 0, '7:3');
  //const mobPath = localizeImages('preview-sales-engine.static-content.images.not-my-info-hero', 0, '7:3');
  const phNo = localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  const callCentNo = stateNo || phNo;
  let offerExpiredMessage = localize('preview-sales-engine.static-content.copy.prospects.offerexpiredspecialist', [
    callCentNo,
    callCentNo,
  ]);
  let packageSoldMessage = localize('preview-sales-engine.static-content.copy.prospects.alreadypurchasespecialist', [
    callCentNo,
    callCentNo,
  ]);

  const pageTitle = isPackageSold
    ? localize('preview-sales-engine.static-content.copy.prospects.greatnewstext')
    : localize('preview-sales-engine.static-content.copy.pbeo.not-my-info-header');

  useEffect(() => {
    dispatch(resetOfferSearch());
    dispatch(resetPinOptFlowDetails());
    dispatch(resetProspectDetails());
    dispatch(resetShowCTA(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClick = () => {
    window.location.href = `tel:${callCentNo}`;
  };
  const handleKeyDown = () => {};

  return (
    <>
      <OfferHeader phone={callCentNo} isThankyou={false} />
      <div className="pinFlowOpt-err-Main-container">
        <img src={path} alt="error" className="pinFlowErrImg" />
        <div className="pinOpt-err-container">
          <div className="pinOpt-err-inner-container">
            <span className="pinOpt-err-head-wrapper">{pageTitle}</span>
            <div className="pinOpt-err-textGroup">
              {isOfferExpired ? (
                <p className="pinOpt-err-desc">
                  <ParseHtml htmlInput={offerExpiredMessage}></ParseHtml>
                </p>
              ) : isPackageSold ? (
                <p className="pinOpt-err-desc">
                  <ParseHtml htmlInput={packageSoldMessage}></ParseHtml>
                </p>
              ) : (
                <>
                  <div className="pinOpt-err-desc">
                    <ParseHtml
                      htmlInput={localize('preview-sales-engine.static-content.copy.pbeo.not-my-info-description')}
                    ></ParseHtml>
                  </div>
                  <div className="pinOpt-err-phone-container">
                    <div
                      className="pinOpt-err-phone-link"
                      onKeyDown={handleKeyDown}
                      aria-label={'phone-err-call'}
                      tabIndex={0}
                      onClick={handleClick}
                    >
                      <img src={phoneLogo} alt="Call Now" className="pinOpt-err-phone-icon" />
                      <span className="pinOpt-err-phone">{callCentNo}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(PinOptFlowError));
