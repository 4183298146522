/* eslint-disable */
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { withRouter, useLocation } from 'react-router-dom';
import withLocalization from '../../components/hoc/withLocalization';
import OfferHeader from '../../components/common/CommonOfferComponent/OfferHeader/OfferHeader';
import PinSearch from '../../components/PinOptimizedSearchPanel/PinSearch';
import { loadOfferDetails } from '../offerSearch/offerSearchSlice';
import { logAnalyticData } from '../../analytics';
import { useMediaQuery } from 'react-responsive';
import { setPinLnDetails } from '../PinOptLeadInfoContainer/PinOptFlowOfferSlice';
import { setUrlDetails } from '../PinOptLeadInfoContainer/PinOptFlowOfferSlice';
import { truncateString } from '../../util/CommonLogic';
import './DefaultPinOptFlowContainer.scss';

const PinOptOffer: FC<any> = (props: any): ReactElement => {
  const { localize, localizeImages, history } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const dispatch = useDispatch();
  const { path } = localizeImages('preview-sales-engine.static-content.images.pin-page-hero', 0, '4:3');
  const mobPath = localizeImages('preview-sales-engine.static-content.images.pin-page-hero', 0, '7:3');
  const prospectDetails = useSelector((state: RootState) => state.offerDetails.prospect);
  const isSubmitErr = useSelector((state: RootState) => state.offerDetails.hasError);
  const errorObj = useSelector((state: RootState) => state.offerDetails.error);
  const details = useSelector((state: RootState) => state.offerDetails);
  const showCTA = useSelector((state: RootState) => state.offerDetails.showCTA);
  const callCenterPhone: any = useSelector((state: RootState) => state.offerDetails.nonFormatedCallcenterNo);
  const isPackageSold: any = useSelector((state: RootState) => state.offerDetails.isPackageSold);
  const errorCode: any = useSelector((state: RootState) => state.offerDetails.errorCode);
  const errDetails = { errPhNo: callCenterPhone || '', isPkgSold: isPackageSold, errorCode: errorCode || '' };
  //const isPinFlow = useSelector((state: RootState) => state.newOfferDetails.isPinOptFlow);
  const pinId: any = props?.match?.params?.pinid || '';
  const getLastName: any = new URLSearchParams(window?.location?.search)?.get('ln') || '';
  const destination: any = new URLSearchParams(window?.location?.search)?.get('dest') || '';
  const brand: any = new URLSearchParams(window?.location?.search)?.get('brand') || '';
  const currentUrl = window.location?.href || '';

  const phNo = localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  const imgPath = isMobile ? mobPath?.path : path;
  if (!prospectDetails && pinId && pinId?.length > 0 && getLastName && getLastName.length > 0) {
    window.scrollTo(0, 0);
    const pinDetailsPayload: any = { pin: pinId.toUpperCase(), lastName: getLastName };
    dispatch(setPinLnDetails(pinDetailsPayload));
    dispatch(loadOfferDetails(pinId.toUpperCase(), getLastName, 'n', true));
  }
  useEffect(() => {
    if (isSubmitErr || errorObj || showCTA) {
      history.push('/pin-error', errDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitErr, errorObj, showCTA]);
  useEffect(() => {
    if (prospectDetails && prospectDetails?.firstName) {
      console.log(prospectDetails);
      history.push('/validate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectDetails]);

  useEffect(() => {
    logAnalyticData(
      {
        event: 'pbePinPage',
        siteSection: 'MVC Optimized PBE',
        pageName: 'MVC PBE PIN Page',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        errorMessage: '',
        offerCode: 'Vacation Offer',
        offerType: 'Open-Dated',
        flowldentifier: 'PIN',
        bookingStepName: 'MVC I PBE PIN Page',
      },
    );
  }, []);
  useEffect(() => {
    const max_length = 255;
    const trunUrl = truncateString(currentUrl, max_length);
    dispatch(
      setUrlDetails({
        url: trunUrl,
        destination: destination,
        brand: brand,
      }),
    );
  });
  const styleBg = {
    backgroundImage: `url("${imgPath}")`,
  };
  return (
    <>
      <OfferHeader phone={phNo} isThankyou={false} />
      <div className="tmvc-pinFlowOpt-Main-container">
        <div className="tmvc-pinFlow-Search-container">
          <div className="pin-inner-container">
            <PinSearch pinId={pinId} ln={getLastName}></PinSearch>
          </div>
        </div>
        <div className="pin-default-img-wrapper">
          <div className="image-pin-container" style={styleBg}></div>
        </div>

        {/*<div className="pinFLow-imgContainer">
          {isMobile ? (
            <img src={mobPath?.path} alt="main" className="pinFlow-laningImg" />
          ) : (
            <img src={path} alt="main" className="pinFlow-laningImg" />
          )}
        </div>*/}
      </div>
    </>
  );
};
export default withRouter(withLocalization(PinOptOffer));
