/* eslint-disable */
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import OfferFooter from '../../common/CommonOfferComponent/OfferFooter/OfferFooter';
import OfferHeader from '../../common/CommonOfferComponent/OfferHeader/OfferHeader';
import withLocalization from '../../hoc/withLocalization';
import { withRouter } from 'react-router';
import ParseHtml from '../../common/ParseHtml';
import './NewOfferError.scss';
import { clearContactInfo } from '../../../features/newOffer/NewOfferSlice';
import { resetOfferSearch, resetProspectDetails } from '../../../features/offerSearch/offerSearchSlice';
import { resetPinOptFlowDetails } from '../../../features/PinOptLeadInfoContainer/PinOptFlowOfferSlice';

import {
  resetPaymentData,
  clearPaymentPage,
  clearPayerAuthDetails,
  resetAllPaymentPageDetails,
} from '../../../features/newOfferPayment/NewOfferPaymentSlice';
interface TestDetailProps {
  history: any;
  localize: any;
  phone?: any;
}

const NewOfferError: FC<any> = (props: TestDetailProps): ReactElement => {
  const { localize } = props;
  const dispatch = useDispatch();
  const contact_num = localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  const newOfferDetails: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const isPinOptFlow: boolean = useSelector((state: RootState) => state.pinOptFlowDetails.pinOptFLow);
  const offerDetails: any = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const newOfferData = isPinOptFlow ? offerDetails : newOfferDetails;
  const errorCode: any = useSelector((state: RootState) => state.newOfferPaymentDetails.paymentErrorStatus);
  const locPhoneNo = (newOfferData?.offers && newOfferData?.offers[0]?.locPhoneNumber) || '';
  const pinOfferData: any = (offerDetails?.offers && offerDetails?.offers[0]) || {};
  const pinPhNo = pinOfferData?.offerPhoneNumber || '';
  const PhoneNo = isPinOptFlow ? pinPhNo : locPhoneNo;
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearContactInfo());
    dispatch(resetPaymentData());
    dispatch(clearPaymentPage());
    dispatch(clearPayerAuthDetails());
    dispatch(resetAllPaymentPageDetails());
    dispatch(resetOfferSearch());
    dispatch(resetPinOptFlowDetails());
    dispatch(resetProspectDetails());

    sessionStorage.clear();
  }, [dispatch]);

  const getErrMsg = (): string => {
    switch (errorCode) {
      case 'IIB_Error':
        return localize(`preview-sales-engine.static-content.copy.Labels.error-message.credit-card-declined-generic`);
      default:
        return localize(`preview-sales-engine.static-content.copy.Labels.error-message.credit-card-declined-system`);
    }
  };
  return (
    <>
      <OfferHeader phone={PhoneNo} />

      <div className="payment-error-details-container">
        <ParseHtml
          htmlInput={localize(
            'preview-sales-engine.static-content.copy.Labels.error-message.pbeo.generic-error-message',
          )}
        />
      </div>
      <br />
      <br />
      {/*<OfferFooter />*/}
    </>
  );
};
export default withRouter(withLocalization(NewOfferError));
