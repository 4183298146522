export const calculateMonthsBetweenDates = (dateString1: any, dateString2: any) => {
  if (dateString1 === '' || dateString2 === '' || dateString1 === undefined || dateString2 === undefined) {
    return '';
  } else {
    const date1: any = new Date(dateString1);
    const date2: any = new Date(dateString2);
    const diffMs = Math.abs(date2 - date1);
    const msInMonth = 30.44 * 24 * 60 * 60 * 1000;
    const diffMonths = diffMs / msInMonth;
    // use Math.round instead of Math.floor to make sure it will round any fraction bigger than or euqal 5.
    return Math.round(diffMonths);
  }
};
export const subtractDollarValues = (value1: any, value2: any) => {
  if (value1 === '' || value2 === '' || value1 === undefined || value2 === undefined) {
    return '';
  }
  const num1 = parseFloat(value1.replace('$', ''));
  const num2 = parseFloat(value2.replace('$', ''));
  const result = num1 - num2;
  const formattedResult = '$' + result.toFixed(2);
  return formattedResult;
};

export const replacePlaceholder = (originalString: string, placeholder: string, replacement: string) => {
  // Check if any parameter is blank
  if (!originalString || !placeholder) {
    return '';
  }

  // Replace all occurrences of the placeholder with the replacement value
  return originalString.replace(placeholder, replacement);
};
export const truncateString = (str: any, maxLength: any) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength);
  } else {
    return str;
  }
};
export const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};
export const capitalizeFirstLetter = (str) => {
  const newStr = str?.trim() || '';
  if (newStr) {
    return str
      .split(' ')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
      .join(' ');
  } else {
    return str;
  }
};
export const replaceAllPlaceholder = (originalString: string, placeholder: string, replacement: string) => {
  // Check if any parameter is blank
  if (!originalString || !placeholder) {
    return '';
  }
  // Replace all occurrences of the placeholder with the replacement value
  return originalString.replaceAll(placeholder, replacement);
};
export const removeDecimalPart = (value: any) => {
  // Check if the value is a valid string that starts with a '$' and contains a number
  let regex = /^(\$?\d+)(\.\d+)?$/;
  let match = value?.match(regex) || '';

  if (match) {
    // The first capture group is the part before the decimal, return it
    return match[1];
  } else {
    return value; // Return the original value if it doesn't match the expected format
  }
};
