export function maskName(fullName: any = '') {
  if (!fullName) return '';
  const [fname, lname] = fullName.split(' ');
  if (!fname || !fname) return '';
  const maskedName = fname + ' ' + lname.charAt(0) + '.';
  return maskedName;
}

export function maskEmail(email: any = '') {
  if (!email) return '';
  return email.replace(/^(.)(.*)(@.*)$/, '$1****$3');
}

export function maskPhoneNumber(phone: any = '') {
  const last4 = phone.slice(-4);
  return phone.replace(/\d/g, '*').slice(0, -4) + last4;
}
