import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import withLocalization from '../../../hoc/withLocalization';
import { RootState } from '../../../../redux/rootReducer';

import './OfferStep.scss';

interface StepProps {
  localize?: any;
  localizeImages?: any;
  step?: any;
  title?: string;
}
const OfferStep: FC<StepProps> = (props: StepProps) => {
  const currentStep = props.step;
  const stepTitle = props.title;
  const { localize } = props;
  const isPinOptFlow: boolean = useSelector((state: RootState) => state.pinOptFlowDetails.pinOptFLow);
  return (
    <>
      <div className="stepe-page-container">
        {!isPinOptFlow && (
          <span className="stepWrapper">
            {localize('preview-sales-engine.static-content.copy.pbeo.step-label')} {currentStep} of 2
          </span>
        )}
        <span className="title-step-wrapper">{`${stepTitle}`}</span>
      </div>
    </>
  );
};
export default withRouter(withLocalization(OfferStep));
