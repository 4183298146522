/* eslint-disable */
import React, { FC, useState } from 'react';
import { withRouter } from 'react-router';
import withLocalization from '../hoc/withLocalization';
import chekMarkLogo from '../../features/siteTheme/Brands/tmvcs/logo/checkMarkLogo.svg';
import ParticipationDetails from '../NewOffer/ParticipationDetails/ParticipationDetails';
import BookingTravelModal from './BookingTravelModal';
import { replacePlaceholder, removeDecimalPart } from '../../util/CommonLogic';
import { formatDate, formatPackageDate } from '../../util/date';
import { capitalizeFirstLetter } from '../../util/CommonLogic';
import './PackageOfferDetails.scss';

interface SearchPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
  offersData?: any;
  leadDetails?: any;
  handlePayClick: () => void;
}
const PackageOfferDetails: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { localize, offersData, leadDetails, history, handlePayClick } = props;
  const [participationModalOpen, setParticipationModalOpen] = useState(false);
  const [bookModalOpen, setBookModalOpen] = useState(false);
  const spOffer = localize('preview-sales-engine.static-content.copy.pbeo.offer-eyebrow') || '';

  const firstName = leadDetails?.firstName || '';
  const capFirstName = capitalizeFirstLetter(firstName);
  const refineSpOffer = replacePlaceholder(spOffer, '{name}', capFirstName);
  const refOfferTitle = refineSpOffer?.split(',');
  const roomType = (offersData?.roomTypes && offersData?.roomTypes[0]) || {};
  const roomDesc = roomType?.roomTypeDescription || '';
  const pkgDays = roomType?.numberOfDays || '';
  const pkgNight = roomType?.numberOfNights || '';
  const stayText = localize('preview-sales-engine.static-content.copy.pbeo.package-overview-days-nights-label');
  const updatedDays = replacePlaceholder(stayText, '{days}', pkgDays);
  const updatedStay = replacePlaceholder(updatedDays, '{nights}', pkgNight);
  const propName = offersData?.property?.name || '';
  const atText = localize('preview-sales-engine.static-content.copy.pbeo.offer-details-at-label');
  const stayAt = `${updatedStay} ${atText} ${propName}`;
  const maxGuest = roomType?.sleepCapacity || '';
  const maxGuestText = localize('preview-sales-engine.static-content.copy.pbeo.package-overview-max-guests-label');
  const updatedGuest = replacePlaceholder(maxGuestText, '{guests}', maxGuest);
  const city = roomType?.property?.city || '';
  const state = roomType?.property?.stateProvince || '';
  const address = `${city}, ${state}`;
  const minCost = offersData?.minimumStartingCost || '';
  const minCostArr = minCost.split('$');
  const MinVal = minCostArr && minCostArr[1] ? removeDecimalPart(minCostArr[1]) : '';
  const gift = (offersData?.premiums && offersData?.premiums[0]) || '';
  const bookToDate = offersData?.bookToDate || '';
  const travelDate = formatPackageDate(bookToDate);
  const travelText = localize('preview-sales-engine.static-content.copy.pbeo.complete-travel-modal-link');
  const updatedTrText = replacePlaceholder(travelText, '{date}', travelDate);
  const payBtnText = localize('preview-sales-engine.static-content.copy.pbeo.claim-offer-cta-label');
  const depositeAmt = roomType?.depositAmount || '';
  const updateDeposite = removeDecimalPart(depositeAmt);
  const updatedPayBtnText = replacePlaceholder(payBtnText, '$199', updateDeposite);
  const retVal = offersData?.retailValue || '';
  const updateRetVal = removeDecimalPart(retVal);
  const formatedRoomDesc = capitalizeFirstLetter(roomDesc);

  const openParticipationModal = () => {
    setParticipationModalOpen(true);
  };
  const closeModal = () => {
    setParticipationModalOpen(false);
  };
  const handlePayBooking = () => {
    // bubble event to parent
    if (handlePayClick) {
      handlePayClick();
    }
    history.push('/payment');
  };
  const clickBookTravel = () => {
    // setBookModalOpen(true);
  };

  const closeBookModal = () => {
    setBookModalOpen(false);
  };
  return (
    <>
      {bookModalOpen && <BookingTravelModal closeModal={closeBookModal} />}
      {participationModalOpen && <ParticipationDetails closeModal={closeModal} />}
      <div className="PackageInfoCard">
        <div className="PackageInfoCard-container">
          <div className="PackageInfo-card-content">
            <div className="pkgInfo-card-Header">
              <span className="pkgInfo-card-specialOfferText">{`${refOfferTitle[0]},`}</span>
              <span className="pkgInfo-card-maximilianoText">{refOfferTitle[1]}</span>
            </div>
            <div className="pkfInfoCard-TopSection">
              <div className="pkfInfoCard-TopSection-title">
                <span className="pkgCardTitle-cnt-Text">{updatedStay}</span>

                <span className="pkgCardTitle-name-Text">{address}</span>
              </div>
              <div className="pkfInfoCard-TopSection-price">
                <span className="pkgCardPrice-from-Text">
                  {localize('preview-sales-engine.static-content.copy.pbeo.offer-details-from-label')}
                </span>
                <div>
                  <span className="pkgCard-symbol-Text">{'$'}</span>

                  <span className="pkgCard-actualVal-Text">{MinVal}</span>
                </div>
                {retVal && (
                  <div className="pkgCardPrice-Value-Container">
                    <s>
                      <span className="pkgCardPrice-value">{retVal}</span>
                    </s>

                    <span className="pkgCardPrice-value">
                      {localize('preview-sales-engine.static-content.copy.pbeo.retail-value-label')}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="pkgCard-list-container">
              <span className="pkgCard-list-head">
                {localize('preview-sales-engine.static-content.copy.pbeo.offer-inclusion-label')}
              </span>
              <div className="pkgCard-list-detail-container">
                <div className="pkgCard-listRow ">
                  <img src={chekMarkLogo} alt={'check'} className="pkgCard-list-cl-check" />
                  <button className="pkgCard-list-cl-detail">{updatedStay}</button>
                </div>
                <div className="pkgCard-listRow ">
                  <img src={chekMarkLogo} alt={'check'} className="pkgCard-list-cl-check" />
                  <button className="pkgCard-list-cl-detail">{propName}</button>
                </div>
                <div className="pkgCard-listRow ">
                  <img src={chekMarkLogo} alt={'check'} className="pkgCard-list-cl-check" />
                  <button className="pkgCard-list-cl-detail">{formatedRoomDesc}</button>
                </div>
                <div className="pkgCard-listRow ">
                  <img src={chekMarkLogo} alt={'check'} className="pkgCard-list-cl-check" />
                  <button className="pkgCard-list-cl-detail">{updatedGuest}</button>
                </div>
                <div className="pkgCard-listRow ">
                  <img src={chekMarkLogo} alt={'check'} className="pkgCard-list-cl-check" />
                  <button className="pkgCard-list-cl-detail" onClick={() => clickBookTravel()}>
                    {updatedTrText}
                  </button>
                </div>
                {gift && (
                  <div className="pkgCard-listRow-bonus">
                    <div>
                      {/*<span className="bonus-cl-bonus-text">
                        {localize('preview-sales-engine.static-content.copy.pbeo.package-summary-gift-label')}
                      </span>*/}
                      <span className="bonus-cl-Gift-text">{gift}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="pkgInfoCard-pay-container">
              <button className="pkgCard-pay-btn" onClick={() => handlePayBooking()}>
                {updatedPayBtnText}
              </button>
              <span className="legalStatement-note">
                {localize('preview-sales-engine.static-content.copy.eligibility-page.qualifications-1.required')}
              </span>
              <button className="pkgCard-detail-Paticipate" onClick={() => openParticipationModal()}>
                {localize('preview-sales-engine.static-content.copy.pbeo.dop-link-label')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(PackageOfferDetails));
