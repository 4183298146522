import { Prospect } from '../features/offerSearch/types';
import { getStateCodeFromName } from './States';
import {
  ADDRESS_LINE,
  ADDRESS_LINE2,
  CITY,
  CITY2,
  EMAIL,
  EMAIL2,
  FIRST_NAME,
  FIRST_NAME2,
  LAST_NAME,
  LAST_NAME2,
  PHONE,
  PHONE2,
  POSTAL_CODE,
  POSTAL_CODE2,
  SPOUSE_FIRST_NAME,
  // eslint-disable-next-line prettier/prettier
  SPOUSE_LAST_NAME
} from './strings';
/**
 * Making Deep Property Access Safe in TypeScript
 */
export const LOC_PIN_TEXT_BOX_MAX_LENGTH: number = 9;
export const LAST_NAME_TEXT_MAX_LENGTH: number = 30;
export const PIN_MAX_LENGTH = 20;
export const getSafe = (fn: any, defaultVal: any) => {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
};

/**
 * Take fields array and transform in object arrray
 */
export const transformFields = (fields: any) => {
  return fields.reduce((a: any, e: any) => {
    const { name, ...rest } = e;
    a[name] = rest;
    return a;
  }, {});
};

export const isValidPin = (pin: string): boolean => {
  const pinValidation: RegExp = new RegExp(`^([a-zA-Z0-9]){5,${PIN_MAX_LENGTH}}$`);
  return pinValidation.test(pin);
};
export const isValidPinOnChange = (pin: string): boolean => {
  const pinValidation: RegExp = new RegExp(`^([a-zA-Z0-9]){1,${PIN_MAX_LENGTH}}$`);
  return pinValidation.test(pin);
};
export const validateFields = (fieldName, fieldValue) => {
  // eslint-disable-next-line no-useless-escape
  const nameValidator: RegExp = new RegExp(/^(?=[a-zA-Z\\s]{1,30})(?!.*[0-9.,<>\/?`~!@#$%&*()\_=+{}\\|\^":;\[\]])/);
  const zipValidator: RegExp = new RegExp(/^\d{5}(?:-\d{4})?$/);
  const emailValidator: RegExp = new RegExp(
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
  );
  const cityValidator: RegExp = new RegExp(`^([a-zA-Z\\s]){1,30}$`);
  const addressValidator: RegExp = new RegExp(/^((.)*([aA-zZ0-9]+)){1,30}/);
  const phoneValidator: RegExp = new RegExp(/^[1-9][0-9]{9}$/);

  const value: string = fieldValue.trim();
  let errMsg = '';
  switch (fieldName) {
    case FIRST_NAME:
      if (value === '') {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.first-name-error#required';
      } else if (!nameValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.first-name-error#invalid';
      } else if (value.indexOf('.') >= 0) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid';
      }
      break;
    case LAST_NAME:
      if (value === '') {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#required';
      } else if (!nameValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid';
      } else if (value.indexOf('.') >= 0) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid';
      }
      break;
    case SPOUSE_FIRST_NAME:
      if (value === '') {
        break;
        //errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.first-name-error#required';
      } else if (!nameValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.first-name-error#invalid';
      } else if (value.indexOf('.') >= 0) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid';
      }
      break;
    case SPOUSE_LAST_NAME:
      if (value === '') {
        //errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#required';
        break;
      } else if (!nameValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid';
      } else if (value.indexOf('.') >= 0) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid';
      }
      break;
    case ADDRESS_LINE:
      if (value === '') {
        errMsg = 'Please enter an address';
      } else if (!addressValidator.test(value)) {
        errMsg = 'Please enter a valid address';
      }
      break;
    case CITY:
      if (value === '') {
        errMsg = 'Please enter a city';
      } else if (!cityValidator.test(value)) {
        errMsg = 'Please enter a valid city';
      }
      break;
    case POSTAL_CODE:
      if (value === '') {
        errMsg = 'Please enter a zip code';
      } else if (!zipValidator.test(value)) {
        errMsg =
          'Please enter a valid zip code' /*'preview-sales-engine.static-content.copy.contact-information-page.zip-code-error#invalid'*/;
      }
      break;
    case EMAIL:
      if (value === '') {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.email-error#required';
      } else if (!emailValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.email-error#invalid';
      }
      break;
    case PHONE:
      if (value === '') {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.phone-error#required';
      } else if (!phoneValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.phone-error#invalid';
      }
      break;
    case FIRST_NAME2:
      if (value === '') {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.first-name-error#required';
      } else if (!nameValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.first-name-error#invalid';
      } else if (value.indexOf('.') >= 0) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid';
      }
      break;
    case LAST_NAME2:
      if (value === '') {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#required';
      } else if (!nameValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid';
      } else if (value.indexOf('.') >= 0) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid';
      }
      break;
    case ADDRESS_LINE2:
      if (value === '') {
        errMsg = 'Please enter an address';
      } else if (!addressValidator.test(value)) {
        errMsg = 'Please enter a valid address';
      }
      break;
    case CITY2:
      if (value === '') {
        errMsg = 'Please enter a city';
      } else if (!cityValidator.test(value)) {
        errMsg = 'Please enter a valid city';
      }
      break;
    case POSTAL_CODE2:
      if (value === '') {
        errMsg = 'Please enter a zip code';
      } else if (!zipValidator.test(value)) {
        errMsg =
          'Please enter a valid zip code' /*'preview-sales-engine.static-content.copy.contact-information-page.zip-code-error#invalid'*/;
      }
      break;
    case EMAIL2:
      if (value === '') {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.email-error#required';
      } else if (!emailValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.email-error#invalid';
      }
      break;
    case PHONE2:
      if (value === '') {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.phone-error#required';
      } else if (!phoneValidator.test(value)) {
        errMsg = 'preview-sales-engine.static-content.copy.contact-information-page.phone-error#invalid';
      }
      break;
    default:
      break;
  }

  return errMsg;
};
export const isEqual = (prospect: Prospect | undefined, billing: any, isContactInfo: boolean = false) => {
  const prospectState =
    prospect && prospect.stateProvince
      ? prospect.stateProvince.length > 2
        ? getStateCodeFromName(prospect?.stateProvince)
        : prospect.stateProvince
      : '';

  if (isContactInfo) {
    const billingState =
      billing && billing.stateProvince
        ? billing.stateProvince.length > 2
          ? getStateCodeFromName(billing?.stateProvince)
          : billing.stateProvince
        : '';

    return (
      billing?.firstName === prospect?.firstName &&
      billing?.lastName === prospect?.lastName &&
      billing?.addressLine1 === prospect?.addressLine1 &&
      billing?.city === prospect?.city &&
      billing?.email === prospect?.email &&
      billingState === prospectState &&
      billing?.postalCode === prospect?.postalCode &&
      billing?.phone === prospect?.phone
    );
  }

  const billingState =
    billing && billing.stateProvince2?.fieldValue
      ? billing.stateProvince2?.fieldValue.length > 2
        ? getStateCodeFromName(billing?.stateProvince2?.fieldValue)
        : billing.stateProvince2?.fieldValue
      : '';

  return (
    billing?.firstName2?.fieldValue === prospect?.firstName &&
    billing?.lastName2?.fieldValue === prospect?.lastName &&
    billing?.addressLine2?.fieldValue === prospect?.addressLine1 &&
    billing?.city2?.fieldValue === prospect?.city &&
    billing?.email2?.fieldValue === prospect?.email &&
    billingState === prospectState &&
    billing?.postalCode2?.fieldValue === prospect?.postalCode &&
    billing?.phone2?.fieldValue === prospect?.phone
  );
};
