/* eslint-disable */
import React, { FC, useState } from 'react';
import { withRouter } from 'react-router';
import withLocalization from '../hoc/withLocalization';
import ParseHtml from '../common/ParseHtml';
import LeadSubmitButton from './LeadSubmitButton';
import ConsentAgreementModal from '../MarketingConsentAgreement/ConsentAgreementModal';
import { maskName, maskEmail, maskPhoneNumber } from './LeadInfoUtils';
import { capitalizeFirstLetter } from '../../util/CommonLogic';
import './LeadInfoDetails.scss';

interface SearchPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
  leadDetails?: any;
}
const LeadInfoDetails: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { localize, leadDetails } = props;
  const [isCheckedContactMe, setIsCheckedContactMe] = useState(false);
  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const capFirstName = capitalizeFirstLetter(leadDetails?.firstName);
  const capLastName = capitalizeFirstLetter(leadDetails?.lastName);
  const fullName = `${capFirstName} ${capLastName}`;
  const clickMarketingConsent = () => {
    setConsentModalOpen(true);
  };
  const closeModal = () => {
    setConsentModalOpen(false);
  };
  const handleCheckboxClick = () => {
    setIsCheckedContactMe(!isCheckedContactMe);
  };
  return (
    <>
      {consentModalOpen && <ConsentAgreementModal closeModal={closeModal} />}
      <div className="leadInfoHeadContainer">
        <span className="leadInfo-head-wrapper">
          {localize('preview-sales-engine.static-content.copy.pbeo.verify-page-header')}
        </span>

        <span className="leadInfo-head-desc">
          {localize('preview-sales-engine.static-content.copy.pbeo.verify-page-description')}
        </span>
      </div>
      <div className="leadInfo-form">
        <div className="leadInfo-RowContainer">
          <span className="leadInfo-form-lbl">
            {localize('preview-sales-engine.static-content.copy.associate-search-page.name-label')}
          </span>
          <span className="leadInfo-form-data">{maskName(fullName)}</span>
        </div>
        <div className="leadInfo-RowContainer">
          <span className="leadInfo-form-lbl">
            {localize('preview-sales-engine.static-content.copy.associate-search-page.email-label')}
          </span>
          <span className="leadInfo-form-data">{maskEmail(leadDetails?.email)}</span>
        </div>
        <div className="leadInfo-RowContainer">
          <span className="leadInfo-form-lbl">
            {localize('preview-sales-engine.static-content.copy.associate-search-page.phone-label')}
          </span>
          <span className="leadInfo-form-data">{maskPhoneNumber(leadDetails?.phone)}</span>
        </div>
        <div className="leadInfo-consent-container">
          <input
            type="checkbox"
            // checked={isPaymentCheck}
            id="checkBox-consent"
            onChange={handleCheckboxClick}
            name="checkbox"
            className="leadInfo-consent-CheckBox"
            aria-label="consent-checkBox"
          />
          <div className="leadInfo-consent-Desc-container">
            <span className="leadInfo-consent-Desc">
              <ParseHtml
                htmlInput={localize('preview-sales-engine.static-content.copy.pbeo.mktg-consent-modal-description')}
              ></ParseHtml>
            </span>
            {/* <button className="marketing-consent-btn" onClick={() => clickMarketingConsent()}>
              {localize('preview-sales-engine.static-content.copy.pbeo.consent-agreement-modal-link')}
            </button>*/}
          </div>
        </div>
        <LeadSubmitButton isValidForm={true} isEdit={false} email={''} phone={''} ischecked={isCheckedContactMe} />
      </div>

      <div className="pinSubmitBtnContainer"></div>
    </>
  );
};
export default withRouter(withLocalization(LeadInfoDetails));
