/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable import/first */
declare var Flex: any;
import React, { FC, ReactElement, useEffect, useState } from 'react';
import withLocalization from '../../hoc/withLocalization';
import CardStorage from './FlexFormStorage';
import { CardType } from './types';
import './CustomCyberSourceForm.scss';
interface CybeSourceFlexFormType {
  context: string;
  style: any;
  cardPlaceHolder: string;
  mvwcMerchantGroupAcceptableCard: CardType[];
  showCVV: boolean;
  cvvPlaceHolder: string;
  formObjectCallback: (n: any) => void;
  isFormValid: (n: boolean) => void;
  emptyCreditCardMessage: string;
  invalidCreditCardMessage: string;
  localize: any;
  invalidCvvMessage: string;
  invalidExpirationErrMsg: string;
  invalidNameMsg: string;
}
const NewCybeSourceFlexComponent: FC<CybeSourceFlexFormType> = (props: CybeSourceFlexFormType): ReactElement => {
  const {
    context,
    style,
    cardPlaceHolder,
    mvwcMerchantGroupAcceptableCard,
    formObjectCallback,
    showCVV,
    cvvPlaceHolder,
    emptyCreditCardMessage,
    isFormValid,
    invalidCreditCardMessage,
    invalidCvvMessage,
    invalidExpirationErrMsg,
    invalidNameMsg,
    localize,
  } = props;

  const [cardError, setCardError] = useState('');
  const [expiration, setExpiration] = useState('');
  const [name, setName] = useState('');
  let currentMonth = (parseInt(new Date().getMonth().toString(), 10) + 1).toString();
  currentMonth = currentMonth.length < 2 ? `0${currentMonth}` : currentMonth;
  const currentYear = new Date().getFullYear();
  //const [expiration, setExpiration]
  const [expirationError, setExpirationError] = useState('');
  const [nameError, setNameError] = useState('');
  const [cvvError, setCvvError] = useState('');
  useEffect(() => {
    CardStorage.resetAllValues();
    CardStorage.setMonth(0);
    CardStorage.setYear(0);
    setExpiration('');
    const flex = new Flex(context);
    const microForm = flex.microform({ styles: style });

    const number = microForm.createField('number', { placeholder: cardPlaceHolder });
    let cardHasError = true;
    let cvvHasError = true;
    CardStorage.isFlexFormValid(isFormValid);

    number.on('change', (data) => {
      if (data.card && data.card.length > 0) {
        var inputCardType: any[] = [];
        var isCardAccepted = false;
        data.card.forEach((element) => {
          if (mvwcMerchantGroupAcceptableCard.some((x) => x.cardId === element.cybsCardType)) {
            inputCardType.push(element);
          }
        });
        if (inputCardType && inputCardType.length > 0) {
          isCardAccepted = true;
          setCardError('');
        } else {
          isCardAccepted = false;
          setCardError(invalidCreditCardMessage);
        }

        if (data.card[0].valid && isCardAccepted) {
          //const cardTypeObj = (inputCardType && inputCardType[0]) || '';
          //setPaymentMethod
          cardHasError = false;
          setCardError('');
        } else if (!isCardAccepted) {
          cardHasError = true;
          setCardError(invalidCreditCardMessage);
        } else {
          cardHasError = true;
          setCardError(invalidCreditCardMessage);
        }
      } else if (data.empty) {
        cardHasError = true;
        setCardError(emptyCreditCardMessage);
      } else {
        cardHasError = true;
        setCardError(invalidCreditCardMessage);
      }
      CardStorage.setCreditCardValidity(!cardHasError);
      if (!showCVV) {
        CardStorage.setCVVValidity(true);
      }
      CardStorage.isFlexFormValid(isFormValid);
    });
    number.on('blur', (data) => {
      CardStorage.setCreditCardValidity(!cardHasError);
      if (cardHasError) {
        setCardError(invalidCreditCardMessage);
      }
      if (!showCVV) {
        CardStorage.setCVVValidity(true);
      }
      CardStorage.isFlexFormValid(isFormValid);
    });
    number.load('#number-container');

    const createTokenInitiator = (cb) => {
      const expYear = `20${CardStorage.getYear()}`;
      const options = {
        expirationMonth: CardStorage.getMonth(),
        expirationYear: expYear,
      };
      microForm.createToken(options, cb);
    };
    if (showCVV) {
      const cvv = microForm.createField('securityCode', { placeholder: cvvPlaceHolder });
      cvv.load('#cvv-container');
      cvv.on('change', (data) => {
        if (data.valid) {
          cvvHasError = false;
          setCvvError('');
        } else {
          cvvHasError = true;
          setCvvError(invalidCvvMessage);
        }
        CardStorage.setCVVValidity(!cvvHasError);
        CardStorage.isFlexFormValid(isFormValid);
      });
      cvv.on('blur', (data) => {
        CardStorage.setCVVValidity(!cvvHasError);
        if (cvvHasError) {
          setCvvError(invalidCvvMessage);
        }
        CardStorage.isFlexFormValid(isFormValid);
      });
    }
    formObjectCallback(createTokenInitiator);
  }, [
    context,
    style,
    cardPlaceHolder,
    mvwcMerchantGroupAcceptableCard,
    formObjectCallback,
    showCVV,
    cvvPlaceHolder,
    isFormValid,
    emptyCreditCardMessage,
    invalidCreditCardMessage,
    invalidCvvMessage,
    currentMonth,
    currentYear,
  ]);
  const isInvalid = (val: any) => {
    // eslint-disable-next-line no-useless-escape
    const nameValidator: RegExp = new RegExp(/^(?=[a-zA-Z\\s]{1,30})(?!.*[0-9.,<>\/?`~!@#$%&*()\_=+{}\\|\^":;\[\]])/);
    return !nameValidator.test(val);
  };
  const checkEmpty = (val: any) => {
    const isEmpty = val?.trim() === '' ? true : false;
    return isEmpty;
  };
  const onNameChange = (e: any) => {
    let value: any = e.target.value;
    //const specialCharRegex = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/;
    if (checkEmpty(value)) {
      setName(value);
      setNameError(invalidNameMsg);
      CardStorage.setCardName(value);
      CardStorage.isFlexFormValid(isFormValid);
    } else if (isInvalid(value)) {
      setName(value);
      setNameError(invalidNameMsg);
      CardStorage.setCardName(value);
      CardStorage.isFlexFormValid(isFormValid);
    } else {
      // If it contains a special character, prevent setting the state
      setName(value);
      CardStorage.setCardName(value);
      CardStorage.isFlexFormValid(isFormValid);
      if (CardStorage.isCardNameValid()) {
        setNameError('');
      } else {
        setNameError(invalidNameMsg);
      }
    }
  };
  const onExpirationChange = (e: any) => {
    let value: any = e.target?.value?.trim();

    if (value) {
      value = value?.replace(/\D/g, '');
      if (value?.length > 2) {
        value = value?.slice(0, 2) + '/' + value?.slice(2);
      }
      if (value) {
        const splitVal = value?.split('/');
        const expMonth = (splitVal && splitVal[0]) || 0;
        const expYear = (splitVal && splitVal[1]) || 0;
        setExpiration(value);
        CardStorage.setMonth(expMonth);
        CardStorage.setYear(expYear);
        CardStorage.isFlexFormValid(isFormValid);
      } else {
        setExpiration(value);
        CardStorage.setMonth(0);
        CardStorage.setYear(0);
        CardStorage.isFlexFormValid(isFormValid);
      }
      if (CardStorage.isMonthYearComboValid()) {
        setExpirationError('');
      } else {
        setExpirationError(invalidExpirationErrMsg);
      }
    } else {
      setExpiration('');
      CardStorage.setMonth(0);
      CardStorage.setYear(0);
    }
  };
  const onHandleExpirationBlur = (e: any) => {
    let value: any = e.target.value;
    if (value?.trim() === '') {
      CardStorage.setMonth(0);
      CardStorage.setYear(0);
      CardStorage.isFlexFormValid(isFormValid);
      setExpirationError(invalidExpirationErrMsg);
    } else {
      const splitVal = value?.split('/');
      const expMonth = (splitVal && splitVal[0]) || 0;
      const expYear = (splitVal && splitVal[1]) || 0;
      CardStorage.setMonth(expMonth);
      CardStorage.setYear(expYear);
      CardStorage.isFlexFormValid(isFormValid);
      if (CardStorage.isMonthYearComboValid()) {
        setExpirationError('');
      } else {
        setExpirationError(invalidExpirationErrMsg);
      }
    }
  };
  const onHandleNameBlur = (e: any) => {
    let value: any = e.target.value;
    if (value?.trim() === '') {
      setNameError(invalidNameMsg);
    }
  };

  return (
    <>
      {' '}
      <div className="custom-cyberSource-form-group">
        <form>
          <div className="cardName-RowContainer">
            <span className="microform-lbl-grp">
              {localize(`preview-sales-engine.static-content.copy.pbeo.cardholders-name-label`)}
            </span>
            <input
              id="cardholderName"
              value={name}
              onChange={onNameChange}
              onBlur={onHandleNameBlur}
              className="input-cyber-cls"
              name="cardholderName"
              autoComplete="cc-name"
              aria-label={'cardHolderName'}
            />
            <span className="microform-inline-err">{nameError}</span>
          </div>
          <div className="form-group ">
            <span className="microform-lbl-grp">
              {localize('preview-sales-engine.static-content.copy.pbeo.credit-card-number-label')}
            </span>

            <div id="number-container" className=" input-cyber-cls"></div>
            <span className="microform-inline-err">{cardError}</span>
          </div>
          <div className="microform-RowContainer">
            <div className="exp-row-container">
              <span className="microform-lbl-grp">
                {localize(`preview-sales-engine.static-content.copy.pbeo.expiration-mm-yy-label`)}
              </span>
              <input
                id="expiration"
                onChange={onExpirationChange}
                onBlur={onHandleExpirationBlur}
                value={expiration}
                className="input-cyber-cls"
                name="expirationName"
                maxLength={5}
                autoComplete="cc-exp"
                aria-label={'expirationName'}
                type="tel"
              />
              <span className="microform-inline-err">{expirationError}</span>
            </div>

            {showCVV && (
              <div className="exp-row-container">
                <span className="microform-lbl-grp">
                  {localize('preview-sales-engine.static-content.copy.Labels.labels#cvv-label')}
                </span>
                <div id="cvv-container" className="input-cyber-cls">{`sss`}</div>
                <span className="microform-inline-err">{cvvError}</span>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default React.memo(withLocalization(NewCybeSourceFlexComponent));
