/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import withLocalization from '../hoc/withLocalization';
import TextInput from '../common/CommonOfferComponent/TextInput/TextInput';
import SelectState from '../common/CommonOfferComponent/SelectState/SelectState';
import { States_US } from '../../util/States';
import { mailingDataValidation, checkBtnDisable, isMailingDataValid } from './MailingAddressValidation';
import {
  setMailingAddressInfo,
  setMailingAddressErrInfo,
  setIdenticalBillingAddress,
} from '../../features/newOfferPayment/NewOfferPaymentSlice';

import { RootState } from '../../redux/rootReducer';
import './MailingAddressDetails.scss';

interface SearchPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
  packageData: any;
}
const MailingAddressDetails: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { localize } = props;
  const dispatch = useDispatch();
  const isPinOptFlow: boolean = useSelector((state: RootState) => state.pinOptFlowDetails.pinOptFLow);
  const newOfferDetails: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const pinofferDetails: any = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const newOfferData = isPinOptFlow ? pinofferDetails : newOfferDetails;
  const prospectDetails: any = useSelector((state: RootState) => state.offerDetails.prospect);
  const mailingData: any = useSelector((state: RootState) => state.newOfferPaymentDetails.mailingAddressInfo);

  const mailingError: any = useSelector((state: RootState) => state.newOfferPaymentDetails.mailingAddressErr);

  const stateData = States_US.find((item) => item.code === prospectDetails?.stateProvince);

  const [mailingErrorMsg, setMailingErrorMsg] = useState({
    addressErrMsg: '',
    cityErrMsg: '',
    zipCodeErrMsg: '',
    stateErrMsg: '',
  });

  const [mailingDetails, setMailingDetails] = React.useState({
    address: prospectDetails?.addressLine1 || '',
    city: prospectDetails?.city || '',
    zipCode: prospectDetails?.postalCode || '',
    state: stateData?.name || '',
  });
  useEffect(() => {
    const payloadData = {
      address: prospectDetails?.addressLine1 || '',
      city: prospectDetails?.city || '',
      zipCode: prospectDetails?.postalCode || '',
      state: stateData?.name || '',
    };
    dispatch(setMailingAddressInfo(payloadData));
  }, []);

  useEffect(() => {
    let tempMsg = {
      addressErrMsg: '',
      cityErrMsg: '',
      zipCodeErrMsg: '',
      stateErrMsg: '',
    };
    const errObj = checkBtnDisable(mailingData);
    dispatch(setMailingAddressErrInfo(errObj));
    const isDisable = isMailingDataValid(errObj);
    if (isDisable) {
      setMailingErrorMsg(tempMsg);
    }
  }, [mailingData]);
  const handleInputChange = (value: string, p1: any, p2: any, p3: any) => {
    handleMailingData(p1, value);
    handleMailingBlur(p1, p2, p3, value);
  };
  const handleInputBlur = (p1: any, p2: any, p3: any) => {
    dispatch(setIdenticalBillingAddress(false));
    handleMailingBlur(p1, p2, p3);
  };

  const handleStateChange = (value: string) => {
    handleMailingData('state', value);
  };

  const handleStateBlur = () => {
    handleMailingBlur('state', 'stateError', 'stateErrMsg');
  };
  const handleMailingData = (field: string, value: string) => {
    const mailingInfoObj = { ...mailingData, [field]: value };
    //setMailingDetails(mailingInfoObj);
    dispatch(setMailingAddressInfo(mailingInfoObj));
  };
  const handleMailingBlur = (name: string, field: string, msg: string, val: any = '') => {
    const chVal = val ? val : mailingData[name];
    const fnError = mailingDataValidation(name, chVal);
    const mailingErrObj = { ...mailingError, [field]: fnError?.isError };
    dispatch(setMailingAddressErrInfo(mailingErrObj));
    setMailingErrorMsg({ ...mailingErrorMsg, [msg]: fnError?.msg });
  };

  return (
    <>
      <div className="mailingDetails-container">
        <div className="mailing-RowContainer">
          <div className="mailing-input-grp">
            <div className="mailing-lbl-grp">
              {localize(`preview-sales-engine.static-content.copy.contact-information-page.street-address`)}
            </div>
            <input
              type="text"
              id="mailingAddress"
              value={mailingData.address}
              onChange={(e) => handleInputChange(e.target.value, 'address', 'addressError', 'addressErrMsg')}
              onBlur={() => handleInputBlur('address', 'addressError', 'addressErrMsg')}
              className={prospectDetails?.addressLine1 ? 'mailing-input-readOnly-cls' : 'mailing-input-cls'}
              maxLength={30}
              aria-label="mailingAddress"
              autoComplete="billing street-address"
              name="addressLine1"
              disabled={prospectDetails?.addressLine1 ? true : false}
            />
            {mailingError?.addressError && mailingErrorMsg.addressErrMsg && (
              <span className="res-inline-err">{localize(mailingErrorMsg?.addressErrMsg)}</span>
            )}
          </div>
        </div>

        <div className="mailing-RowContainer">
          <div className="mailing-input-grp">
            <div className="mailing-lbl-grp">
              {localize(`preview-sales-engine.static-content.copy.contact-information-page.city`)}
            </div>
            <input
              type="text"
              id="mailingCity"
              value={mailingData.city}
              onChange={(e) => handleInputChange(e.target.value, 'city', 'cityError', 'cityErrMsg')}
              onBlur={() => handleInputBlur('city', 'cityError', 'cityErrMsg')}
              className={prospectDetails?.city ? 'mailing-input-readOnly-cls' : 'mailing-input-cls'}
              maxLength={30}
              aria-label="mailingCity"
              autoComplete="billing address-level2"
              name="mailingCity"
              disabled={prospectDetails?.city ? true : false}
            />
            {mailingError.cityError && mailingErrorMsg.cityErrMsg && (
              <span className="res-inline-err">{localize(mailingErrorMsg.cityErrMsg)}</span>
            )}
          </div>

          <SelectState
            label={localize('preview-sales-engine.static-content.copy.contact-information-page.state')}
            value={mailingData.state}
            onChange={handleStateChange}
            handleSelectBlur={handleStateBlur}
            isError={mailingError?.stateError}
            errMsg={localize(mailingErrorMsg.stateErrMsg)}
            StateData={States_US}
            isErrBorder={true}
            isFillAddress={false}
            isReadOnly={stateData ? true : false}
          />

          <div className="mailing-input-grp">
            <div className="mailing-lbl-grp">
              {localize(`preview-sales-engine.static-content.copy.contact-information-page.zip-code`)}
            </div>
            <input
              type="tel"
              id="mailingzipCode"
              value={mailingData.zipCode}
              onChange={(e) => handleInputChange(e.target.value, 'zipCode', 'zipCodeError', 'zipCodeErrMsg')}
              onBlur={() => handleInputBlur('zipCode', 'zipCodeError', 'zipCodeErrMsg')}
              className={prospectDetails?.postalCode ? 'mailing-input-readOnly-cls' : 'mailing-input-cls'}
              maxLength={10}
              aria-label="mailingzipCode"
              autoComplete="billing postal-code"
              name="mailingzipCode"
              disabled={prospectDetails?.postalCode ? true : false}
            />
            {mailingError.zipCodeError && mailingErrorMsg.zipCodeErrMsg && (
              <span className="res-inline-err">{localize(mailingErrorMsg.zipCodeErrMsg)}</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(MailingAddressDetails));
