import React, { FC } from 'react';
//import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

//import { RootState } from '../../redux/rootReducer';
import ParseHtml from '../common/ParseHtml';
import withLocalization from '../hoc/withLocalization';
import { replacePlaceholder } from '../../util/CommonLogic';
import { removeDecimalPart } from '../../util/CommonLogic';
import './PackageBottomDetails.scss';

interface SearchPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
  depositeAmt: any;
}
const PackageBottomDetails: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { localize, localizeImages, depositeAmt } = props;
  const step1Img = localizeImages('preview-sales-engine.static-content.images.step-1-icon', 0, '1:1');
  const step2Img = localizeImages('preview-sales-engine.static-content.images.step-2-icon', 0, '1:1');
  const step3Img = localizeImages('preview-sales-engine.static-content.images.step-3-icon', 0, '1:1');
  const stIcon1 = step1Img?.path || '';
  const stIcon2 = step2Img?.path || '';
  const stIcon3 = step3Img?.path || '';
  const st1_desc = localize('preview-sales-engine.static-content.copy.pbeo.step-1-description');
  const updateDeposite = removeDecimalPart(depositeAmt);
  const updated_st1_desc = replacePlaceholder(st1_desc, '$199', updateDeposite);

  return (
    <>
      <div className="pkgInfo-bottom-section">
        <div className="packageInfoBtm-content">
          <div className="packageBtm-header-container">
            <span className="packageBtm-header-title">
              <ParseHtml
                htmlInput={localize('preview-sales-engine.static-content.copy.pbeo.ready-to-vacation-section-header')}
              ></ParseHtml>
            </span>
            <div className="pkg-details-btm-bar"></div>

            <span className="packageBtm-header-desc">
              <ParseHtml
                htmlInput={localize(
                  'preview-sales-engine.static-content.copy.pbeo.ready-to-vacation-section-description',
                )}
              ></ParseHtml>
            </span>
          </div>
          <div className="packageBtm-group-container">
            <div className="packageBtm-group-list">
              <div className="pkgCard-step-baseImg">
                <img src={stIcon1} alt="error" className="pkgCard-list-cl-step" />
              </div>

              <div className="pkgBtm-list-grpText">
                <span className="pkgBtmgrpText-step">
                  <ParseHtml
                    htmlInput={localize('preview-sales-engine.static-content.copy.pbeo.step-1-label')}
                  ></ParseHtml>
                </span>
                <span className="pkgBtmgrpText-step-desc">
                  <ParseHtml htmlInput={updated_st1_desc}></ParseHtml>
                </span>
              </div>
            </div>
            <div className="packageBtm-group-list">
              <div className="pkgCard-step-baseImg">
                <img src={stIcon2} alt="error" className="pkgCard-list-cl-step" />
              </div>
              <div className="pkgBtm-list-grpText">
                <span className="pkgBtmgrpText-step">
                  <ParseHtml
                    htmlInput={localize('preview-sales-engine.static-content.copy.pbeo.step-2-label')}
                  ></ParseHtml>
                </span>
                <span className="pkgBtmgrpText-step-desc">
                  <ParseHtml
                    htmlInput={localize('preview-sales-engine.static-content.copy.pbeo.step-2-description')}
                  ></ParseHtml>
                </span>
              </div>
            </div>
            <div className="packageBtm-group-list">
              <div className="pkgCard-step-baseImg">
                <img src={stIcon3} alt="error" className="pkgCard-list-cl-step" />
              </div>
              <div className="pkgBtm-list-grpText">
                <span className="pkgBtmgrpText-step">
                  <ParseHtml
                    htmlInput={localize('preview-sales-engine.static-content.copy.pbeo.step-3-label')}
                  ></ParseHtml>
                </span>
                <span className="pkgBtmgrpText-step-desc">
                  <ParseHtml
                    htmlInput={localize('preview-sales-engine.static-content.copy.pbeo.step-3-description')}
                  ></ParseHtml>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(PackageBottomDetails));
