export const OFFER_REVIEW_PATH = '/review';
export const VIEW_OFFERS_PATH = '/view-offers';
export const OFFER_DESTINATION_PATH = '/offer-destination';
export const OFFER_DATES_PATH = '/dates';
export const OFFER_ELIGIBILITY_PATH = '/eligibility';
export const OFFER_CONTACT_INFO_PATH = '/contact-info';
export const OFFER_CONFIRMATION_PATH = '/confirmation';
export const COMMON_OFFER_DETAILS_PATH = '/common-offer-details';
export const CONTACT_INFO_MODAL_PATH = '/contact-info-state-ineligible';
//LOC paths
export const VERIFY = '/verify';
export const OFFER_STATE_DESTINATION_PATH = '/loc-offer-destination';
export const OFFER_LOC_REVIEW_PATH = '/loc-review';
export const VIEW_LOC_OFFERS_PATH = '/loc-offers';
export const OFFER_LOC_DESTINATION_PATH = '/loc-offer-destination';
export const OFFER_LOC_DATES_PATH = '/loc-dates';
export const OFFER_LOC_ELIGIBILITY_PATH = '/loc-eligibility';
export const OFFER_LOC_CONTACT_INFO_PATH = '/loc-contact-info';
export const OFFER_LOC_CONFIRMATION_PATH = '/loc-confirmation';

export const PIN: string = 'pin';
export const LOC: string = 'loc';

export const TERMS_OF_USE_PATH = '/terms-of-use';
export const PRIVACY_PATH = '/privacy';
export const STATE_AND_LEGAL_DISCLOSURES_PATH = '/state-and-legal-disclosures';
export const ACCESSIBILITY_STATEMENT_PATH = '/accessibility-statement/';

export const CONFIRM = 'confirm';

export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const ADDRESS_LINE = 'addressLine1';
export const CITY = 'city';
export const POSTAL_CODE = 'postalCode';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const FIRST_NAME2 = 'firstName2';
export const LAST_NAME2 = 'lastName2';
export const ADDRESS_LINE2 = 'addressLine2';
export const CITY2 = 'city2';
export const POSTAL_CODE2 = 'postalCode2';
export const EMAIL2 = 'email2';
export const PHONE2 = 'phone2';
export const SPOUSE_FIRST_NAME = 'spouseFirstName';
export const SPOUSE_LAST_NAME = 'spouseLastName';

export const TourRequired = 'TR';

export const NA = 'n/a';
export const NACaps = 'N/A';

export const none = 'none';
export const B4 = 'B4';

export const TEST_DATA_DETAILS_PATH = '/testdata-details';
export const NEWOFFER_CONTACT_INFORMATION_PATH = '/contact-information';
export const NEWOFFER_PAYMENT_PATH = '/payment';
export const NEWOFFER_THANKYOU_PATH = '/thank-you';
export const NEWOFFER_ERROR_PATH = '/error';

export const TMVC_URL = 'book.marriottvacationclubs.com';
export const CURRENCY_CODE = 'USD';
export const IP_ADDR_REQUEST_URL = 'https://api.ipify.org?format=json';

export const PIN_OPT_VALIDATE_PATH = '/validate';
export const PIN_OPT_OFFER_DETAILS_PATH = '/offer-details';
export const PIN_OPT_ERROR = '/pin-error';
