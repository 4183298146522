/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';
import './ConsentAgreementModal.scss';
import galleryModalCloseLogo from '../../features/siteTheme/Brands/tmvcs/logo/galleryModalCloseLogo.svg';
import ParseHtml from '../common/ParseHtml';
interface consentProps {
  localize?: any;
  image?: string;
  closeModal: () => void;
}
const ConsentAgreementModal: FC<any> = (props: consentProps): ReactElement => {
  const { localize, closeModal } = props;
  const consentDetails = localize('preview-sales-engine.static-content.copy.pbeo.contact-consent-checkbox-description');
  const handleOutsideClick = () => {
    closeModal();
  };
  return (
    <div className="modal-overlay-part" aria-label="close" onClick={handleOutsideClick}>
      <div className="consent-modal-part" onClick={(e) => e.stopPropagation()}>
        <div className="consent-modal-head-container">
          <div className="consent-modal-text-head">
            {localize('preview-sales-engine.static-content.copy.pbeo.mktg-consent-modal-header')}
          </div>
          <div className="consent-modal-part-close">
            <input
              className="modal-close-btn"
              src={galleryModalCloseLogo}
              type="image"
              onClick={closeModal}
              aria-label="close"
            />
          </div>
        </div>
        <div className="consent-modal-content-part">{<ParseHtml htmlInput={consentDetails}></ParseHtml>}</div>
      </div>
    </div>
  );
};
export default React.memo(withLocalization(withBrands(ConsentAgreementModal)));
