import React, { FC, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withLocalization from '../../components/hoc/withLocalization';
import OfferHeader from '../../components/common/CommonOfferComponent/OfferHeader/OfferHeader';
import { RootState } from '../../redux/rootReducer';
import PackageOfferDetails from '../../components/PackageOfferDetails/PackageOfferDetails';
import PackageBottomDetails from '../../components/PackageOfferDetails/PackageBottomDetails';
import { useMediaQuery } from 'react-responsive';
import { logAnalyticData } from '../../analytics';
import { Offer, OfferResponse } from '../../features/offerSearch/types';
import './PinOptOfferDetailsContainer.scss';

let logAnalyticCalled = false;
const PinOptOfferDetailsContainer: FC<any> = (props: any): ReactElement => {
  const { history } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });

  const prospectDetails = useSelector((state: RootState) => state.offerDetails.prospect);
  const isSubmitErr = useSelector((state: RootState) => state.offerDetails.hasError);
  const pinInfoDetails: any = useSelector((state: RootState) => state.pinOptFlowDetails.pinDetails);
  const urlData: any = useSelector((state: RootState) => state.pinOptFlowDetails.urlData);

  const offerDetails: OfferResponse = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const pin = pinInfoDetails?.pin || '';

  const offerData: Offer = (offerDetails?.offers && offerDetails.offers[0]) || '';
  const offerProperty = offerData?.property || {};
  const roomType = (offerData?.roomTypes && offerData?.roomTypes[0]) || {};
  const depositeAmt = roomType?.depositAmount || '';
  const imgListArr = (offerProperty?.imgList4 && offerProperty?.imgList4[0]) || {};
  const heroImg = (imgListArr && imgListArr.path) || '';
  const submitError = useSelector((state: RootState) => (isSubmitErr ? state.offerDetails.error?.errorMessageKey : ''));
  const analyliticFunc = (adobeEvent: string, adobeStepName: string, googleEvent: string) => {
    const offer: Offer = (offerDetails?.offers && offerDetails.offers[0]) || {};
    const offerCode = offer?.offerCode || 'Vacation Offer';
    const offerType = () => {
      const showCalendar = offer?.showCalendar;
      switch (showCalendar) {
        case 'H':
          return 'Open-Dated';
        case 'S':
          return 'Dated';
        default:
          return 'Open-Dated';
      }
    };
    const roomType = (offer?.roomTypes && offer?.roomTypes[0]) || {};
    const stateCode = roomType?.property?.stateCode;
    const destination = urlData?.destination;
    const brand = urlData?.brand && urlData?.brand.length > 0 ? urlData?.brand : roomType?.property?.brandName;
    const loc = offer?.loc || '';
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: googleEvent,
      ecommerce: {
        items: [
          {
            item_id: roomType?.property?.marshaCode,
            item_name: roomType?.property?.name,
            item_brand: brand,
            item_category: destination,
            item_category2: loc,
            item_category3: 'USA',
            item_category4: stateCode,
            item_category5: roomType?.property?.city,
            item_category6: prospectDetails?.stateProvince,
            item_category7: offerType(),
            item_category8: offerCode,
            item_catagory9: pin,
            price: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
            retail_price: offer?.retailValue?.replace(/[$,]/g, '') || '',
            package_price: roomType?.startingCost?.replace(/[$,]/g, '') || '',
            flow_identifier: 'pin',
            quantity: 1,
          },
        ],
      },
    });
    logAnalyticData(
      {
        event: adobeEvent,
        siteSection: 'MVC Optimized PBE',
        pageName: 'MVC PBE PIN OfferPage',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        errorMessage: submitError,
        offerCode: offerCode,
        offerType: offerType(),
        flowIdentifier: 'PIN',
        formLOC: loc,
        destination: destination,
        resortCity: roomType?.property?.city,
        resortState: roomType?.property?.stateCode,
        resortCountry: 'USA',
        resortName: roomType?.property?.name,
        resortBrand: brand,
        retail_price: offer?.retailValue?.replace(/[$,]/g, '') || '',
        packagePrice: roomType?.startingCost?.replace(/[$,]/g, '') || '',
        depositPrice: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
        currency: 'USD',
        quanity: '1',
        resortMarsha: roomType?.property?.marshaCode,
        userSelectedState: prospectDetails?.stateProvince,
        bookingStepName: adobeStepName,
      },
    );
  };

  const handlePayClick = () => {
    analyliticFunc('pbePinBeginCheckout', 'MVC I PBE PIN Begin Checkout', 'begin_checkout');
  };

  const styleBg = {
    backgroundImage: `url("${heroImg}")`,
  };
  const phNo = offerData?.offerPhoneNumber || '';
  const errPhNo = { errPhNo: phNo || '' };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!offerData) {
      history.push('/pin-error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isSubmitErr) {
      history.push('/pin-error', errPhNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitErr]);
  useEffect(() => {
    if (!logAnalyticCalled) {
      analyliticFunc('pbePinOfferPage', 'MVC I PBE PIN Offer Page', 'view_item');
      logAnalyticCalled = true;
    }
  }, []);

  return (
    <>
      <OfferHeader phone={phNo} isThankyou={false} />
      <div className="tmvc-offer-main-container">
        {!isMobile && (
          <>
            <div className="heroImg-container" style={styleBg}>
              <PackageOfferDetails
                offersData={offerData}
                leadDetails={prospectDetails}
                handlePayClick={handlePayClick}
              />
            </div>
            <PackageBottomDetails depositeAmt={depositeAmt} />
          </>
        )}
        {isMobile && (
          <>
            <img src={heroImg} alt="main" className="pinFlowPkg-Img" />
            <PackageOfferDetails offersData={offerData} leadDetails={prospectDetails} handlePayClick={handlePayClick} />
            <PackageBottomDetails depositeAmt={depositeAmt} />
          </>
        )}
      </div>
    </>
  );
};
export default withRouter(withLocalization(PinOptOfferDetailsContainer));
