import React, { FC, useState } from 'react';
import { withRouter } from 'react-router';
import { LAST_NAME_TEXT_MAX_LENGTH, PIN_MAX_LENGTH, isValidPin, validateFields } from '../../util/validation';
import { isValidPinOnChange } from '../../util/validation';
import { LAST_NAME } from '../../util/strings';
import { useDispatch, useSelector } from 'react-redux';
import { loadOfferDetails } from '../../features/offerSearch/offerSearchSlice';
import { setPinLnDetails } from '../../features/PinOptLeadInfoContainer/PinOptFlowOfferSlice';
import { setOfferFlow } from '../../features/newOffer/NewOfferSlice';
// import ParseHtml from '../common/ParseHtml';
import withLocalization from '../hoc/withLocalization';
import { logAnalyticData } from '../../analytics';
import { OfferResponse, Offer } from '../../features/offerSearch/types';
import { RootState } from '../../redux/rootReducer';
import './PinFlowSearchPanel.scss';

interface SearchPanelProps {
  localize?: any;
  localizeImages?: any;
  pinId?: string;
  history: any;
  ln?: string;
}
const PinSearch: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { pinId, localize, ln } = props;
  const [pinValue, setPinValue] = useState(pinId || '');
  //const isPinDisable = pinId ? true : false;
  const [lastName, setLastName] = useState(ln || '');
  const [buttonStatusEnabled, setButtonStatusEnabled] = useState(false);
  const [pinHasError, setPinHasError] = useState(false);
  const [pinValidated, setPinValidated] = useState(false);
  const [lastNameHasError, setLastNameHasError] = useState(false);
  const dispatch = useDispatch();
  const offerDetails: OfferResponse = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const isSubmitErr = useSelector((state: RootState) => state.offerDetails.hasError);
  const submitError = useSelector((state: RootState) => (isSubmitErr ? state.offerDetails.error?.errorMessageKey : ''));
  const offer: Offer = (offerDetails?.offers && offerDetails.offers[0]) || '';
  const offerCode = offer?.offerCode || 'Vacation Offer';
  const offerType = () => {
    const showCalendar = offer?.showCalendar;
    switch (showCalendar) {
      case 'H':
        return 'Open-Dated';
      case 'S':
        return 'Dated';
      default:
        return 'Open-Dated';
    }
  };
  /* useEffect(() => {
    pinId && pinId.toLowerCase() !== 'error' ? setPinValue(pinId) : setPinValue('');
    pinId ? (isValidPin(pinId) ? setPinHasError(false) : setPinHasError(true)) : setPinHasError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/

  const handleClickSubmit = () => {
    if (!isValidPin(pinValue)) {
      setPinHasError(true);
      setButtonStatusEnabled(false);
      setPinValidated(true);
    } else {
      setPinHasError(false);
      dispatch(setOfferFlow());
      if (pinValue?.length > 0 && lastName.length > 0) {
        window.scrollTo(0, 0);
        const pinDetailsPayload: any = { pin: pinValue.toUpperCase(), lastName: lastName };
        dispatch(setPinLnDetails(pinDetailsPayload));
        dispatch(loadOfferDetails(pinValue.toUpperCase(), lastName, 'n', true));
        window.dataLayer.push({
          event: 'enter-pin-flow',
        });
        logAnalyticData(
          {
            event: 'pbePinPageSubmit',
            siteSection: 'MVC Optimized PBE',
            pageName: 'MVC PBE PIN Page',
            pageHierarchy: '',
            server: 'MVC',
            website: 'MVC react',
            language: 'en',
          },
          {
            errorMessage: submitError,
            offerCode: offerCode,
            offerType: offerType(),
            flowldentifier: 'PIN',
            bookingStepName: 'MVC I PBE PIN Page',
          },
          {
            linkName: 'PBE PIN Page Submit',
            linkClickPage: 'MVC PBE PIN Page',
          },
        );
      }
    }
  };

  const handlePinChange = (evt) => {
    const pinData = evt.target.value;
    setPinValue(pinData);
    if ((!isValidPinOnChange(pinData) || (!isValidPin(pinData) && pinValidated)) && pinData) {
      setPinHasError(true);
      setButtonStatusEnabled(false);
    } else {
      setPinHasError(false);
      if (lastName && !lastNameHasError && pinData) {
        setButtonStatusEnabled(true);
      } else {
        setButtonStatusEnabled(false);
      }
    }
  };

  const handleLastNameChange = (evt) => {
    const lastNameVal = evt.target.value;
    setLastName(lastNameVal);
    if (pinValue && lastNameVal) {
      setButtonStatusEnabled(true);
    }

    if (validateFields(LAST_NAME, lastNameVal)) {
      if (lastNameVal) {
        setLastNameHasError(true);
      }
      setButtonStatusEnabled(false);
    } else {
      setLastNameHasError(false);
      if (pinValue && !pinHasError) {
        setButtonStatusEnabled(true);
      } else {
        setButtonStatusEnabled(false);
      }
    }
  };
  return (
    <>
      <div className="pinCardTitleContainer">
        <span className="pin-head-wrapper">
          {localize('preview-sales-engine.static-content.copy.prospects.letsfindoffer')}
        </span>

        <span className="pinCardDescription">
          {localize('preview-sales-engine.static-content.copy.pbeo.find-offer-description')}
        </span>
      </div>
      <div className="nameRowContainer">
        <div className="pin-input-grp">
          <div className="offer-input-bill-grp">
            <div className="res-lbl-grp">
              {localize('preview-sales-engine.static-content.copy.pbeo.preferred-invitation-label')}
            </div>
            <input
              type="text"
              id={'pin-optimized'}
              value={pinValue}
              onChange={handlePinChange}
              className="pin-input-cls"
              maxLength={PIN_MAX_LENGTH}
              aria-label={'opt-pin'}
              //disabled={isPinDisable}
            />
            {pinHasError && (
              <div className="pin-error">
                <span className="res-inline-err">
                  {localize('preview-sales-engine.static-content.copy.associate-search-page.pin-field-errors#pattern')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="nameRowContainer">
        <div className="ln-input-grp">
          <div className="offer-input-bill-grp">
            <div className="res-lbl-grp">
              {localize('preview-sales-engine.static-content.copy.pbeo.pbeo-last-name-label')}
            </div>
            <input
              type="text"
              id={'pin-optimized-LastName'}
              value={lastName}
              onChange={handleLastNameChange}
              className="pin-input-cls"
              maxLength={LAST_NAME_TEXT_MAX_LENGTH}
              aria-label={'opt-pin'}
              autoComplete={'family-name'}
            />
            {lastNameHasError && (
              <div className="ln-error">
                <span className="res-inline-err">
                  {localize('preview-sales-engine.static-content.copy.prospects.lastnamevalidation')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pinSubmitBtnContainer">
        <button
          disabled={!buttonStatusEnabled}
          onClick={handleClickSubmit}
          className={!buttonStatusEnabled ? `pinSubmitBtnDisable` : `pinSubmitBtn`}
        >
          {localize('preview-sales-engine.static-content.copy.pbeo.submit-button-label')}
        </button>
      </div>
    </>
  );
};
export default withRouter(withLocalization(PinSearch));
