import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import withLocalization from '../../hoc/withLocalization';
//import packageOverviewLogo from '../../../features/siteTheme/Brands/tmvcs/logo/package-overview.svg';
import packageOverviewIcon from '../../../features/siteTheme/Brands/tmvcs/logo/tmvc-pkg-icon.svg';
import downArrorwIcon from '../../../features/siteTheme/Brands/tmvcs/logo/downArrowLogo.svg';
import upArrowIcon from '../../../features/siteTheme/Brands/tmvcs/logo/upArrowLogo.svg';
import { RootState } from '../../../redux/rootReducer';
import { refinePackageData } from '../../../util/packagingOverviewMapping';
import ImgGalleryModal from '../ImgGalleryModal/ImgGalleryModal';
import PackageCollapseDetails from './PackageCollapseDetails';
import { useMediaQuery } from 'react-responsive';
import './PackageOverview.scss';

interface SearchPanelProps {
  localize?: any;
  history: any;
}
const PackageOverview: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { localize } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const refineOfferData: any = (newOfferData?.offers && newOfferData?.offers[0]) || '';

  const isPinOptFlow: boolean = useSelector((state: RootState) => state.pinOptFlowDetails.pinOptFLow);
  const offerDetails: any = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const pinOfferData: any = (offerDetails?.offers && offerDetails?.offers[0]) || '';

  const refineData = isPinOptFlow ? pinOfferData : refineOfferData;

  const packageData = refinePackageData(refineData);
  const [modalOpen, setModalOpen] = useState(false);
  const [Collapse, setCollapse] = useState(true);
  const initiaColState: boolean = !isMobile ? true : !Collapse ? true : false;
  const roomTypeData = (refineData?.roomTypes && refineData?.roomTypes[0]) || '';
  const imgData = (roomTypeData?.property?.imgList4 && roomTypeData?.property?.imgList4[0]) || '';
  const path = imgData?.path || '';
  const [isSeeDetails, setIsSeeDetails] = useState<boolean>(initiaColState);
  const imgDataArr = roomTypeData?.imgList16?.extImages || [];
  const intImgDataArr = roomTypeData?.imgList16?.intImages || [];
  const showGallery = imgDataArr.length > 0 || intImgDataArr > 0 ? true : false;

  useEffect(() => {
    const seeDetailsCheck: boolean = !isMobile ? true : !Collapse ? true : false;
    setIsSeeDetails(seeDetailsCheck);
  }, [Collapse, isMobile]);
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const handleKeyDown = () => {};
  return (
    <>
      {modalOpen && <ImgGalleryModal closeModal={closeModal} />}
      <div className="packgeOverviewContainer">
        <div className="innerPcOverviewContainer">
          <div className="pkg-inner-title-container">
            <span className="packageOverViewTitle">
              {localize('preview-sales-engine.static-content.copy.pbeo.package-overview-label')}
            </span>
            {!isSeeDetails && (
              <div className="packagePriceContainer">
                <span className="pkg-price-head">Due today:</span>
                <span className="pkg-price-content">{`${packageData?.dueToday}`}</span>
              </div>
            )}
          </div>
          <div className="packageOverviewOfferCardContainer">
            <span className="pkgOverviewOfferCardTitle">
              {localize('preview-sales-engine.static-content.copy.pbeo.offer-eyebrow-text')}
            </span>
            <div className="pkg-resortInfo-container">
              <div className="resortInfo_img-container">
                <img src={path} alt={'house-icon'} className="thumbnail-package-overview" />

                {showGallery && (
                  <div className="Pkg-icon-container">
                    <input
                      type="image"
                      src={packageOverviewIcon}
                      alt={'house-icon'}
                      className="pkg-overview-icon"
                      onClick={() => openModal()}
                      id={'heroImg-id'}
                    />
                  </div>
                )}
              </div>
              <div className="resortInfo_desc-container">
                <span className="pkg-dsc-name-wrapper">{`${packageData?.name}`}</span>
                <span className="pkg-dsc-area-wrapper">{`${packageData?.address}`}</span>
              </div>
            </div>
          </div>
          {!isSeeDetails && (
            <div className="seeDetails-Container">
              <div
                className="seeDetail-link-container"
                onClick={() => setCollapse(false)}
                arial-label={'see-detail'}
                role="button"
                tabIndex={0}
                onKeyDown={handleKeyDown}
                id={'seeDetail-link-container'}
              >
                <div className="seeDetail-linkText-div">
                  <span className="seeDetail-linkText-span"> {`See Details`}</span>
                  <div className="seeDetail-link-line"></div>
                </div>
                <img src={downArrorwIcon} alt={'down-Arrow'} className="img-arrow-pkg" />
              </div>
            </div>
          )}
          {isSeeDetails && <PackageCollapseDetails packageData={packageData} />}
          {isSeeDetails && isMobile && (
            <div className="seeDetails-Container">
              <div
                className="seeDetail-link-container"
                tabIndex={0}
                onClick={() => setCollapse(true)}
                arial-label={'ollapse-detail'}
                role="button"
                onKeyDown={handleKeyDown}
                id={'seeDetail-link-button'}
              >
                <div className="seeDetail-linkText-div">
                  <span className="seeDetail-linkText-span"> {`Collapse`}</span>
                  <div className="seeDetail-link-line"></div>
                </div>
                <img src={upArrowIcon} alt={'down-Arrow'} className="img-arrow-pkg" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(PackageOverview));
